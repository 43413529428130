import { getSongsForTableReq } from "../../services/axios/axios.songs"
import React from "react"
import moment from "moment"


export const columnsContentSongs = [
  {
    Header: "ID",
    accessor: "_id"
  },
  {
    Header: "Title",
    accessor: "name"
  },
  {
    Header: "Link",
    accessor: "link"
  },
  {
    Header: "Platform",
    accessor: "platform"
  },
  {
    Header: "Date Created",
    accessor: "createdAt"
  },
  {
    Header: "Updated At",
    accessor: "updatedAt"
  },
  {
    Header: "Action",
    accessor: "action"
  }
]



export const getSongs = async (skip: any, limit: number,
  sortVal: any, realNameFilter: any,
  activateFilterStatus: any, setActivateAlertStatus: any, setDeactivateAlertStatus: any,
  setPermGroupIdForReactivate: any, setPermGroupIdForDelete: any,
  setDeleteAlertStatus: any, history: any) => {
  let results = await getSongsForTableReq(
    skip, limit,
    sortVal, realNameFilter,
    activateFilterStatus
  )
  const songsFromBack = results?.data?.songs ? results.data.songs : []
  const amount = results?.data.amount ? results.data.amount : 0
  const groupsToPush: any[] = []
  if (songsFromBack && songsFromBack?.length) {
    songsFromBack.map((song: any) => {
      groupsToPush.push({
        _id: (
          <div
            className={song.is_active ? "" : "inactive"}
            key={"id-" + song._id}
          >
            {song._id.slice(0, 10) + "..."}
          </div>
        ),
        name: (
          <div
            className={song.is_active ? "" : "inactive"}
            key={"name-" + song._id}
          >
            {song?.name}
          </div>
        ),
        link: (
          <div
            className={song.is_active ? "" : "inactive"}
            key={"desc-" + song._id}>
            {song?.link?.slice(0, 20) + "..."}
          </div>
        ),
        platform: (
          <div
            className={song.is_active ? "" : "inactive"}
            key={"type-" + song._id}>
            {song?.platform}
          </div>
        ),
        createdAt: (
          <div
            className={song.is_active ? "" : "inactive"}
            key={"date-" + song._id}
          >
            {moment(song?.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
        updatedAt: (
          <div
            className={song.is_active ? "" : "inactive"}
            key={"date2-" + song._id}
          >
            {song?.updatedAt
              ? moment(song?.updatedAt).format("MM/DD/YYYY")
              : "-"}
          </div>
        ),
        action: (
          <div className="action-in-table" key={"action-" + song._id}>
            <i
              key={"ico1-" + song._id}
              data-id={song._id}
              className="typcn typcn-edit perm-action"
              onClick={() => history.push("/songs/" + song._id)}
            ></i>
            {song?.is_active ? (
              <i
                key={"ico2-" + song._id}
                data-id={song._id}
                onClick={e => {
                  setDeactivateAlertStatus(true)
                  setPermGroupIdForReactivate(song._id);
                }}
                className="typcn typcn-flash-outline perm-action"
              ></i>
            ) : (
              <i
                key={"ico3-" + song._id}
                className="typcn typcn-flash perm-action"
                data-id={song._id}
                onClick={e => {
                  setPermGroupIdForReactivate(song._id);
                  setActivateAlertStatus(true)
                }}
              ></i>
            )}
            <i
              key={"ico4-" + song._id}
              className="typcn typcn-delete-outline perm-action"
              data-id={song._id}
              onClick={(e) => {
                setPermGroupIdForDelete(song._id)
                setDeleteAlertStatus(true)
              }}

            ></i>
          </div>
        )

      })
    })
  }
  return { groupsToPush, amount }
}