import {
  TabContent,
  TabPane,
  Row,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardFooter,
  CardBody,
  Alert,
  Card
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"

import { useHistory } from "react-router-dom"

import { getPermsReq } from "../../../../services/axios/axios.default_permissions"
import { setNavValues } from "../../../../store/nav_breadcrumbs/actions"
import SmallSwitch from "../../../../components/shared/smallSwitch"
import { Styles } from "../../user/user_table"
import config from "../../../../config"

const GroupPermCreatePage = () => {
  //setState block=======================================================================
  //role constructor input states
  const [groupName, setGroupName] = useState("")
  const [permType, setPermType] = useState("")
  const [groupDesc, setGroupDesc] = useState("")
  //data from backend
  const [defPermsStatic, setDefPermsStatic] = useState([])
  const [defPermsToRender, setDefPermsToRender] = useState([])
  const [permsArr, setPermsArr] = useState([])
  ///other
  const [activeTab, setActiveTab] = useState("1")
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertStatusWarn, setalertStatusWarn] = useState(false)
  const [alertText, setalertText] = useState("")

  const history = useHistory()

  //on change handlers=========================================================================
  const nameOnChange = e => {
    const nameToChange = e.target.value
    setGroupName(nameToChange)
  }

  const descOnChange = e => {
    setGroupDesc(e.target.value)
  }

  const typeOnChange = e => {
    const type = e.target.value
    setPermType(type)
    const permsWithChecked = []
    permsArr.map(perm => {
      perm.isChecked = false
      if (perm.type === type) {
        permsWithChecked.push(perm)
      }
    })
    setActiveTab("2")
    setDefPermsToRender(permsWithChecked)
    setDefPermsStatic(permsWithChecked)
  }

  const searchFilterCreate = e => {
    const searchString = e.target.value
    if (searchString === "") {
      setDefPermsToRender(defPermsStatic)
      return
    }

    const filteredArr = defPermsStatic.filter(perm =>
      perm?.name.toLowerCase().includes(searchString.toLowerCase())
    )
    setDefPermsToRender(filteredArr)
  }

  const permsOnChange = (value, id, isChecked) => {
    const filteredArr = defPermsToRender
    const elementOnCheck = filteredArr.find(perm => perm._id === id)
    elementOnCheck.isChecked = !isChecked
    setDefPermsToRender([...filteredArr])
    // setMagicRender(!magicRender)
  }
  ///async handlers

  //on save modal handler
  const saveChanges = async () => {
    if (!groupName || !permType) {
      setActiveTab("1")
      return
    }
    const permArrToSend = []
    defPermsToRender.map(perm => {
      if (perm.isChecked) {
        permArrToSend.push(perm._id)
      }
    })
    const reqObj = {
      name: groupName,
      type: permType,
      description: groupDesc,
      permissionID: permArrToSend
    }
    const response = await createPermReq(reqObj)

    if (response.data === "succesfully created") {
      setActiveTab("1")
      setAlertStatus(true)
      setGroupName("")
      setPermType("")
    } else {
      setalertStatusWarn(true)
      setalertText(
        response?.data?.message
          ? response?.data?.message
          : "Something went wrong"
      )
      setTimeout(() => {
        setalertStatusWarn(true)
      }, 5000)
    }
  }
  /////async block===========================================================

  ///get default perms
  const getPerms = async () => {
    const permsRes = await getPermsReq()
    const perms = permsRes?.data

    if (perms?.length) {
      setPermsArr(perms)
    }
  }

  const unassignedPerm = defPermsToRender?.map((perm, index) => {
    if (!perm.isChecked && perm.is_active) {
      return (
        <Row className="perm-check" key={"right-switch" + perm._id}>
          <SmallSwitch
            perm={perm}
            permsOnChange={permsOnChange}
          />
        </Row>
      )
    }
  })
  //useEffect block

  useEffect(() => {
    getPerms()
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title1: "Permission Groups", title2: "Create" }))
  }, [])

  return (
    <Container className="p-0 user-main-wrapper" fluid>
      <Styles className=" styles-inner-pages-wrapper">
        <Container fluid className="edit-user-wrapper hide-scrollbar">
          <AvForm>
            <div className="modal-body">
              <Col className="user-data-inputs">
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      <span className="d-none d-sm-block">General</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                        <span className="d-none d-sm-block">
                          Permissions Assignment
                        </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody className="mb-0">
                            <div className="mb-3">
                              <AvField
                                name="name"
                                label="Name  "
                                value={groupName}
                                onChange={nameOnChange}
                                placeholder="Add name"
                                type="text"
                                errorMessage="Add name"
                                validate={{
                                  required: { value: true }
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="description"
                                label="Description"
                                value={groupDesc}
                                onChange={descOnChange}
                                placeholder="Add description (not required)"
                                type="text"
                                validate={false}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="type"
                                onChange={typeOnChange}
                                value={permType}
                                label="Pick a type  "
                                type="select"
                                errorMessage="Choose Type"
                                validate={{
                                  required: { value: true }
                                }}
                              >
                                <option></option>
                                {" "}
                                <option value="role">Role</option>
                                {" "}
                                <option value="subscription">
                                  Subscription
                                </option>
                                {" "}
                              </AvField>
                            </div>
                          </CardBody>
                          <CardFooter>
                            <button
                              type="button"
                              onClick={() => {
                                history.push("../permission-groups/")
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={saveChanges}
                            >
                              Create Permission Group
                            </button>
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody className="assign-content">
                            <Row className="perms-check">
                              <Col sm={12}>
                                <div className="mb-3">
                                  <AvField
                                    name="searchFilterCreate"
                                    onChange={searchFilterCreate}
                                    // value={userDataToRender.fullname}
                                    label="Search by name  "
                                    type="text"
                                  />
                                </div>
                              </Col>
                              {!defPermsToRender?.length && (
                                <Col sm={12}>
                                  <h5>You should pick a type first</h5>
                                </Col>
                              )}
                              {defPermsToRender &&
                              !!defPermsToRender?.length && (
                                <Col sm={6} className="unassigned-col">
                                  <h5>Assigned permissions</h5>
                                  <br />
                                  {defPermsToRender?.map((perm, index) => {
                                    if (perm.isChecked) {
                                      return (
                                        <Row
                                          className="perm-check"
                                          key={"left-switch" + perm._id}
                                        >
                                          <SmallSwitch
                                            perm={perm}
                                            permsOnChange={permsOnChange}
                                          />
                                        </Row>
                                      )
                                    }
                                  })}
                                </Col>
                              )}
                              {defPermsToRender &&
                              !!defPermsToRender?.length && (
                                <Col sm={6} className="assigned-col">
                                  <h5>Unassigned permissions</h5>
                                  <br />
                                  {unassignedPerm[0] === undefined &&unassignedPerm.length<2? <h5>No available</h5> : unassignedPerm}

                                </Col>
                              )}
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <button
                              type="button"
                              onClick={() => {
                                history.push("../permission-groups/")
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={saveChanges}
                            >
                              Create Permission Group
                            </button>
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </div>
          </AvForm>
          {alertStatus ? (
            <SweetAlert
              className="alert-perm-group"
              title="Success!"
              success
              isAnimation={true}
              confirmBtnText="Return"
              cancelBtnText="Continue"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="primary"
              onConfirm={() => {
                history.push("../permission-groups")
              }}
            >
              Permission Group created!
            </SweetAlert>
          ) : null}
          {alertStatusWarn && (
            <Alert color="warning" style={{ position: "absolute", bottom: 50 }}>
              <strong>Oops!</strong> {alertText}
            </Alert>
          )}
        </Container>
      </Styles>
    </Container>
  )
}

export default GroupPermCreatePage
