import {
  Alert,
  Card,
  CardBody, CardFooter,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import { Styles } from "../user/user_table"
import { AvField, AvForm } from "availity-reactstrap-validation"
import SmallSwitch from "../../../components/shared/smallSwitch"
import React, { useEffect, useState } from "react"
import { getDynPermsReq } from "../../../services/axios/axios.perm_groups"
import { getSubReq, updateSubReq } from "../../../services/axios/axios.subs"
import { useHistory, useParams } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import config from "../../../config"

export default function EditSubscriptionPage() {
  //use state block
  const [activeTab, setActiveTab] = useState("1")
  const [subPermStatic, setsubPermStatic] = useState([])
  const [subPermsToRender, setsubPermsToRender] = useState([])
  const [subName, setSubName] = useState("")
  const [subDescription, setSubDescription] = useState("")
  const [modalStatus, setModalStatus] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [alertStatusWarn, setAlertStatusWarn] = useState(false)

  //const
  const history = useHistory()
  const params = useParams()
  //input handlers
  const nameOnChange = (e) => {
    setSubName(e.target.value)
  }

  const descriptionOnChange = (e) => {
    setSubDescription(e.target.value)
  }

  const searchFilterCreate = (e) => {
    const searchString = e.target.value
    if (searchString === "") {
      setsubPermsToRender(subPermStatic)
      return
    }

    const filteredArr = subPermStatic.filter(perm =>
      perm?.name.toLowerCase().includes(searchString.toLowerCase())
    )
    setsubPermsToRender(filteredArr)
  }

  const permsOnChange = (value, id, isChecked) => {
    const filteredArr = subPermsToRender
    const elementOnCheck = filteredArr.find(perm => perm._id === id)
    elementOnCheck.isChecked = !isChecked
    setsubPermsToRender([...filteredArr])
  }

  //async blokc
  const getCurrentSub = async () => {
    const response = await getSubReq(params.id)
    const sub = response?.data
    if (!!sub && sub.name) {
      setSubName(sub.name)
      setSubDescription(sub.description)
    }
    if (!!sub && sub.permissions.length) {
      return sub.permissions
    } else {
      return []
    }


  }
const getDynPerms = async () => {
    const subPermsToFill = []
    const response = await getDynPermsReq()
    const currentPerms = await getCurrentSub()
    if (response?.data && Array.isArray(response?.data)) {
      response.data.forEach(perm => {
        if (perm?.type === "subscription") {
          const matched = currentPerms.find(el=>el._id===perm._id)
          perm.isChecked = !!matched
          subPermsToFill.push(perm)
        }
      })
    }
    setsubPermsToRender(subPermsToFill)
    setsubPermStatic(subPermsToFill)
  }


  const saveChanges = async () => {
    const permArrReqObj = []
    subPermsToRender.map(perm => {
      if (perm.isChecked) {
        permArrReqObj.push(perm._id)
      }
    })
    const reqObj = {
      name: subName,
      description: subDescription,
      permissions: permArrReqObj
    }
    if (!subName) {
      setActiveTab("1")
      return
    }
    const response = await updateSubReq(params.id,reqObj)
    if (response.data === "succesfully updated") {
      setModalStatus(true)
      return
    }
    if (response?.data?.status === 500) {
      setAlertText(response.data.name + " " + response.data.message)
      setAlertStatusWarn(true)
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 4000)
    } else {
      setAlertText("Unknown Error. Please contact administrator")
      setAlertStatusWarn(true)
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 4000)
    }
  }
  const unassignedPerm = subPermsToRender?.map((perm, index) => {
    if (!perm.isChecked && perm.is_active) {
      return (
        <Row className="perm-check" key={"right-switch" + perm._id}>
          <SmallSwitch
            perm={perm}
            permsOnChange={permsOnChange}
          />
        </Row>
      )
    }
  })

  //use effect block
  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({mainTitle:config.breadCrumbsFirstTitle,title1:'Subscriptions',title2:'Edit'}))
    getDynPerms()
  }, [])
  return (
    <Container fluid className="providers-main-wrapper" style={{ position: "relative" }}>
      {modalStatus ? (
        <SweetAlert
          title="Success"
          success={true}

          confirmBtnText="Return"
          confirmBtnBsStyle="success"

          onConfirm={() => {
            history.push("../subscriptions/")
            setModalStatus(false)
          }}
        >
          Subscription Plan updated!
        </SweetAlert>
      ) : null}
      <Styles className="provider-white-card" style={{ position: "relative" }}>
        <AvForm>
          <Nav tabs className="nav-tabs-custom">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  setActiveTab("1")
                }}
              >
                <span className="d-none d-sm-block">General</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  setActiveTab("2")
                }}
              >
                        <span className="d-none d-sm-block">
                          Permissions Assignment
                        </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <div className="modal-body">
                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="Name  "
                          value={subName}
                          onChange={nameOnChange}
                          placeholder="Add name"
                          type="text"
                          errorMessage="Add name"
                          validate={{
                            required: { value: true }
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="fullname"
                          onChange={descriptionOnChange}
                          value={subDescription}
                          label="Description  "
                          placeholder="Add description (not required)"
                          type="text"
                        />
                      </div>

                    </div>
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody className="assign-content">
                      <CardTitle className="h4"></CardTitle>
                      <Row className="perms-check">
                        <Col sm={12}>
                          <div className="mb-3">

                            <AvField
                              name="searchFilterCreate"
                              onChange={searchFilterCreate}
                              // value={userDataToRender.fullname}
                              label="Search by name  "
                              type="text"
                            />

                          </div>
                        </Col>
                        {!subPermsToRender?.length && (
                          <Col sm={12}>No results</Col>
                        )}
                        {subPermsToRender &&
                        !!subPermsToRender?.length && (
                          <Col sm={6} className="unassigned-col">
                            <h5>Assigned permissions</h5>
                            <br />
                            {subPermsToRender?.map((perm, index) => {
                              if (perm.isChecked) {
                                return (
                                  <Row className="perm-check" key={"left-switch" + perm._id}>
                                    <SmallSwitch
                                      perm={perm}
                                      permsOnChange={permsOnChange}
                                    />
                                  </Row>
                                )
                              }
                            })}
                          </Col>
                        )}
                        {subPermsToRender &&
                        !!subPermsToRender?.length && (
                          <Col sm={6} className="assigned-col">
                            <h5>Unassigned permissions</h5>
                            <br />
                            {unassignedPerm[0] === undefined &&unassignedPerm.length<2? <h5>No available</h5> : unassignedPerm}
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <CardFooter>
            <button
              type="button"
              onClick={() => {
                history.push("../subscriptions/")
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
              onClick={saveChanges}
            >
              Update Plan
            </button>
          </CardFooter>
        </AvForm>
      </Styles>
      {alertStatusWarn && (
        <div style={{ zIndex: "9999", position: "absolute", width: "100%", bottom: "-10vh", left: 0 }}>
          <Alert color="warning" className="bg-warning text-white success-custom"
                 style={{ width: "max-content", position: "relative", marginLeft: "auto", marginRight: "auto" }}
          >
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>
  )
}