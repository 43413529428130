import {
  TabContent,
  TabPane,
  CardText,
  Row,
  Col,
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
  Label,
  Table,
  CardBody,
  CardTitle,
  Card,
  CardFooter,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Switch from "react-switch"

import { getUserReq, updateUserReq } from "../../../services/axios/axios.user"
import { getRolesReq } from "../../../services/axios/axios.roles"
import { getSubsReq } from "../../../services/axios/axios.subs"
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import PasswordCustomValidation from "../../../components/datapages/userspage/passwordCustomValidation/validationComponent"

import {
  Offsymbol,
  OnSymbol,
} from "../../../components/shared/switchSymbolsDefault"

import chevron from "../../../assets/default/images/ico/chevronForSelect.png"
import "./user-edit.page.scss"
import { Styles } from "../user/user_table"
import config from "../../../config"

const UserEditPage = () => {
  //state block
  const [currentUserID, setCurrentUserID] = useState("")
  const [roles, setRoles] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [alertErrStatus, setAlertErrStatus] = useState(false)
  const [alertSucStatus, setAlertSucStatus] = useState(false)
  const [loginDate, setLogins] = useState("")
  const [userToSend, setUserToSend] = useState({
    userName: "",
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    roleID: "",
    notes: "",
    password: "",
    phoneNumber: "",
    is_active: true,
    subscriptionID: "",
  })
  const [userIp, setUserIP] = useState("")
  const [userLocation, setUserLocation] = useState("")
  const [subArr, setSubsArr] = useState([])
  const [userDataToRender, setUsersDataToRender] = useState({})
  const [confirmedPassword, setConfirmedPassword] = useState("")
  const [errMessageAlert, setErrMessageAlert] = useState("")
  //validation
  const [isLengthValid, setIsLengthValid] = useState(false)
  const [isUpperLetterValid, setIsUpperLetterValid] = useState(false)
  const [isLowerLetterValid, setIsLowerLetterValid] = useState(false)
  const [isAnyNumberValid, setIsAnyNumberValid] = useState(false)
  const [isStringAlphaNumValid, setIsStringAlphaNumValid] = useState(false)
  const [matchStatus, setMatchStatus] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [emailErrorStatus, setEmailErrorStatus] = useState(false)
  const [phoneErrorStatus, setPhoneErrorStatus] = useState(false)
  const [isFullNameValid, setIsFullNameValid] = useState(true)
  const [isFistNameValid, setIsFistNameValid] = useState(true)
  const [isLastNameValid, setIsLastNameValid] = useState(true)

  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true)
  const [anyChangesStatus, setAnyChangesStatus] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(true)

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [activatePasswordFields, setActivatePasswordFields] = useState(false)

  //consts
  const history = useHistory()
  const params = useParams()

  const editFormInputs = e => {
    setAnyChangesStatus(true)
    const target = e.target
    const value = target.value
    switch (target.name) {
      case "login":
        userToSend.userName = value
        setUserToSend(userToSend)
        break
      case "fullname":
        setIsFullNameValid(value.trim().length >= 2)
        userToSend.fullName = value
        setUserToSend(userToSend)
        break
      case "firstName":
        setIsFistNameValid(value.trim().length >= 2)
        userToSend.firstName = value
        setUserToSend(userToSend)
        break
      case "lastName":
        setIsLastNameValid(value.trim().length >= 2)
        userToSend.lastName = value
        setUserToSend(userToSend)
        break
      case "email":
        userToSend.email = value
        setUserToSend(userToSend)
        setIsEmailValid(
          /^(([^()[\]\\.,;:\s@"]+(\.[^()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            String(e.target.value).toLowerCase()
          )
        )
        break
      case "role":
        userToSend.role = value
        setUserToSend(userToSend)
        break
      case "notes":
        userToSend.notes = value
        setUserToSend(userToSend)
        break
      case "password":
        userToSend.password = value
        setIsLengthValid(value.trim().length >= 8)
        //upperblock
        setIsUpperLetterValid(value.toLowerCase() != value)
        //lower block
        setIsLowerLetterValid(value.toUpperCase() != value)
        //number block
        setIsAnyNumberValid(/\d/i.test(value))
        setIsStringAlphaNumValid(/(?=.*?[#?!@$%^&*-])/.test(value))
        if (!!confirmedPassword) {
          setMatchStatus(value === confirmedPassword)
        }
        setUserToSend(userToSend)
        break
      case "password1":
        setMatchStatus(
          e.target.value.toString() === userToSend.password.toString()
        )
        setConfirmedPassword(value)
        break
      case "phone":
        userToSend.phoneNumber = value
        setIsPhoneNumberValid(/^([+\d].*)?\d$/.test(value))
        setUserToSend(userToSend)
        break
      case "sub":
        userToSend.subscription = value
        setUserToSend(userToSend)
        break
    }
  }

  ///async handlers

  const updateUser = async () => {
    const emailInput = document.querySelector("#email")
    const phoneNumberInput = document.querySelector("#phone")

    if (!formValid || !anyChangesStatus) {
      return
    }
    try {
      const response = await updateUserReq(currentUserID, userToSend)
      if (
        response?.data?.message &&
        response?.data?.message?.includes("email:")
      ) {
        const message = response.data.message
        const userEmail = message.split("{")[1].split(`"`)[1]
        setErrMessageAlert("User with " + userEmail + " already exists.")
        setEmailErrorStatus(true)
        emailInput.classList.add("is-invalid")
        setTimeout(() => {
          setEmailErrorStatus(false)
          emailInput.classList.remove("is-invalid")
        }, 3000)
        emailInput.focus()
        return
      }

      if (
        response?.data?.message &&
        response?.data?.message?.includes("phoneNumber:")
      ) {
        const message = response.data.message
        const userPhone = message.split("{")[1].split(`"`)[1]
        setErrMessageAlert("User with " + userPhone + " already exists.")
        phoneNumberInput.focus()
        phoneNumberInput.classList.add("is-invalid")
        setPhoneErrorStatus(true)
        setTimeout(() => {
          setPhoneErrorStatus(false)
          phoneNumberInput.classList.remove("is-invalid")
        }, 3000)

        return
      }
      if (response?.data === "updated successfully") {
        setAlertSucStatus(true)
      } else {
        setAlertErrStatus(true)
        setTimeout(() => {
          setAlertErrStatus(false)
        }, 3000)
      }
    } catch (err) {
      console.log("update error: " + err)
    }
  }

  const getSubs = async () => {
    const subRes = await getSubsReq()
    const subs = subRes?.data
    if (subs?.length) {
      setSubsArr(subs)
    }
  }

  const getUser = async id => {
    const response = await getUserReq(id)
    const user = response?.data
    setLogins(response?.data?.login)
    const locationArr = user?.location?.split(" ")
    if (locationArr?.length) {
      setUserIP(locationArr[0])
      setUserLocation(locationArr[1] + " " + locationArr[2])
    }
    if (user) {
      setUsersDataToRender(user)
      setUserToSend({
        userName: user.username,
        is_active: user.is_active,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        notes: user.notes,
        roleID: user.role,
        subscriptionID: user?.subscription,
        phoneNumber: user.phoneNumber,
      })
    }
  }

  const getRoles = async () => {
    const response = await getRolesReq()
    setRoles(response?.data)
  }

  useEffect(() => {
    if (activatePasswordFields) {
      setIsPasswordValid(
        matchStatus &&
          isStringAlphaNumValid &&
          isAnyNumberValid &&
          isLowerLetterValid &&
          isUpperLetterValid &&
          isLengthValid
      )
    }
  }, [
    matchStatus,
    isStringAlphaNumValid,
    isAnyNumberValid,
    isLowerLetterValid,
    isUpperLetterValid,
    isLengthValid,
  ])
  useEffect(() => {
    setFormValid(
      isFullNameValid && isEmailValid && isPasswordValid && isPhoneNumberValid
    )
  }, [isFullNameValid, isEmailValid, isPasswordValid, isPhoneNumberValid])
  ///useEffect block
  useEffect(() => {
    let id = params.id
    setCurrentUserID(id)
    config.reduxStore.dispatch(
      setNavValues({
        mainTitle: config.breadCrumbsFirstTitle,
        title1: "Users",
        title2: "Edit",
      })
    )
    getUser(id)
    getRoles()
    getSubs()
  }, [])

  //main block
  return (
    <Container className="p-0 user-main-wrapper" fluid>
      <Styles className=" styles-inner-pages-wrapper">
        <Container fluid className="edit-user-wrapper hide-scrollbar">
          <Nav tabs className="nav-tabs-custom">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  setActiveTab("1")
                }}
              >
                <span className="d-none d-sm-block">Profile</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  setActiveTab("2")
                }}
              >
                <span className="d-none d-sm-block">Usage</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <div className="modal-body">
                      <AvForm>
                        <div className="mb-3">
                          <AvField
                            name="firstName"
                            onChange={editFormInputs}
                            value={userDataToRender.firstName}
                            label="First Name  "
                            className={isFistNameValid ? "" : "is-invalid"}
                            placeholder="Type first Name"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Enter first Name",
                              },
                              minLength: {
                                value: 2,
                                errorMessage: "At least 2 symbols",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="lastName"
                            onChange={editFormInputs}
                            value={userDataToRender.lastName}
                            label="Last Name  "
                            className={isLastNameValid ? "" : "is-invalid"}
                            placeholder="Type last Name"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Enter last Name",
                              },
                              minLength: {
                                value: 2,
                                errorMessage: "At least 2 symbols",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            value={userDataToRender.email}
                            className={isEmailValid ? "" : "is-invalid"}
                            onChange={editFormInputs}
                            placeholder="Enter Valid Email"
                            type="email"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Enter Email",
                              },
                              email: {
                                value: true,
                                errorMessage: "Invalid Email",
                              },
                            }}
                          />
                          {emailErrorStatus && (
                            <small style={{ color: "red" }}>
                              User with this email exists
                            </small>
                          )}
                        </div>
                        <div className="switch-message-wrapper mb-2">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#626ed4"
                            onClick={e => {}}
                            onChange={e => {
                              setIsPasswordValid(activatePasswordFields)
                              setActivatePasswordFields(!activatePasswordFields)
                            }}
                            checked={activatePasswordFields}
                          />
                          <div
                            className={
                              activatePasswordFields
                                ? "enabled-pass-message ms-1"
                                : "ms-1"
                            }
                          >
                            {activatePasswordFields
                              ? "Password editing enabled"
                              : "Password editing disabled"}
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label>Password</Label>
                          <div className="pass-input-wrapper">
                            <AvField
                              name="password"
                              type={isPasswordVisible ? "text" : "password"}
                              id="modal-pass-1"
                              onChange={editFormInputs}
                              disabled={!activatePasswordFields}
                              className={
                                !isPasswordValid && activatePasswordFields
                                  ? "is-invalid"
                                  : ""
                              }
                              onFocus={e => {
                                const el = e.target
                                setTimeout(() => {
                                  el.selectionStart = e.target.value.length
                                  el.selectionEnd = e.target.value.length
                                }, 10)
                              }}
                              errorMessage="Enter password"
                              validate={{ required: { value: true } }}
                            >
                              mdi-eye-off-outline
                            </AvField>
                            {!isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={e => {
                                  const el =
                                    document.getElementById("modal-pass-1")
                                  el.focus()
                                  setIsPasswordVisible(true)
                                }}
                              />
                            )}
                            {isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-off-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={e => {
                                  const el =
                                    document.getElementById("modal-pass-1")
                                  el.focus()
                                  setIsPasswordVisible(false)
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label>Confirm Password</Label>
                          <div className="pass-input-wrapper">
                            <AvField
                              disabled={!activatePasswordFields}
                              name="password1"
                              id="modal-pass-2"
                              type={isPasswordVisible ? "text" : "password"}
                              onChange={editFormInputs}
                              className={
                                !isPasswordValid && activatePasswordFields
                                  ? "is-invalid"
                                  : ""
                              }
                              onFocus={e => {
                                const el = e.target
                                setTimeout(() => {
                                  el.selectionStart = e.target.value.length
                                  el.selectionEnd = e.target.value.length
                                }, 10)
                              }}
                              errorMessage="Enter Re-password"
                              validate={{
                                required: { value: true },
                                match: { value: "password" },
                              }}
                            />
                            {!isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={e => {
                                  const el =
                                    document.getElementById("modal-pass-2")
                                  el.focus()
                                  setIsPasswordVisible(true)
                                }}
                              />
                            )}
                            {isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-off-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={e => {
                                  const el =
                                    document.getElementById("modal-pass-2")
                                  el.focus()
                                  setIsPasswordVisible(false)
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <PasswordCustomValidation
                          activatePasswordFields={activatePasswordFields}
                          isLengthValid={isLengthValid}
                          isUpperLetterValid={isUpperLetterValid}
                          isLowerLetterValid={isLowerLetterValid}
                          isAnyNumberValid={isAnyNumberValid}
                          isStringAlphaNumValid={isStringAlphaNumValid}
                          matchStatus={matchStatus}
                        />
                        <div className="mb-3">
                          <AvField
                            label="Phone number"
                            name="phone"
                            className=""
                            value={userDataToRender.phoneNumber}
                            onChange={editFormInputs}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Enter phone number",
                              },
                              pattern: {
                                value: "^([+\\d].*)?\\d$",
                                errorMessage: "Only Digits and +",
                              },
                            }}
                          />
                          {phoneErrorStatus && (
                            <small style={{ color: "red" }}>
                              User with this phone number exists
                            </small>
                          )}
                        </div>
                        <div className="mb-3 chevron-container">
                          <img src={chevron} alt="" className="chevron-img" />
                          <AvField
                            name="role"
                            value={userDataToRender?.role}
                            onChange={editFormInputs}
                            label="Role  "
                            placeholder="Choose role"
                            type="select"
                            errorMessage="Choose Role"
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value={userDataToRender?.role?._id}>
                              {userDataToRender?.role?.name}
                            </option>{" "}
                            {roles?.map(role => {
                              if (
                                role?.is_active &&
                                role?._id !== userDataToRender.role?._id
                              ) {
                                const string = role.name
                                return (
                                  <option
                                    value={role._id}
                                    key={"option-role" + role._id}
                                  >
                                    {string.charAt(0).toUpperCase() +
                                      string.slice(1)}
                                  </option>
                                )
                              }
                            })}
                          </AvField>
                        </div>
                        <div className="mb-3 chevron-container">
                          <img src={chevron} alt="" className="chevron-img" />
                          <AvField
                            name="sub"
                            value={userDataToRender?.subscription}
                            onChange={editFormInputs}
                            label="Subscription  "
                            placeholder="Choose subscription"
                            type="select"
                            errorMessage="Choose Sub"
                          >
                            <option value={userDataToRender?.subscription?._id}>
                              {userDataToRender?.subscription?.name}
                            </option>{" "}
                            {subArr?.map(sub => {
                              if (
                                sub.is_active &&
                                sub._id !== userDataToRender?.subscription?._id
                              ) {
                                const string = sub.name
                                return (
                                  <option value={sub._id} key={string}>
                                    {string.charAt(0).toUpperCase() +
                                      string.slice(1)}
                                  </option>
                                )
                              }
                            })}
                          </AvField>
                        </div>
                        <div className="mb-3">
                          <AvField
                            onChange={editFormInputs}
                            value={userDataToRender.notes}
                            name="notes"
                            label="Notes  "
                            placeholder="Add note (not required)"
                            type="textarea"
                          />
                        </div>
                        <div className="modal-footer"></div>
                      </AvForm>
                    </div>
                  </CardText>
                </Col>
              </Row>
              {alertErrStatus && (
                <Alert
                  color="danger"
                  className="bg-danger text-white mb-0 error-custom-user-edit"
                >
                  <strong>Oops!</strong> {errMessageAlert}
                </Alert>
              )}
              <CardFooter className="back-update-wrapper">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                  className="btn btn-secondary waves-effect me-2"
                  data-dismiss="modal"
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={updateUser}
                >
                  Update User
                </button>
              </CardFooter>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Recent logins </CardTitle>

                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Timestamp</th>
                              <th>IP</th>
                              <th>Location</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {moment(loginDate).format(
                                  "MMMM DD YYYY, h:mm A"
                                )}
                              </td>
                              <td>{userIp}</td>
                              <td>{userLocation}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Fabric Nodes: </CardTitle>

                      <p className="card-title-desc">
                        In progress. Below data is emulated
                      </p>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Date Connected</th>
                              <th>Last Updated</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>UCSF</td>
                              <td>6/2/2020 4:50PM</td>
                              <td>6/3/2020 12:50PM</td>
                              <td className="action-cell">
                                {" "}
                                <i
                                  className="typcn typcn-delete-outline"
                                  style={{ fontSize: "15px" }}
                                ></i>
                                <i
                                  className="typcn typcn-upload-outline ms-4"
                                  style={{ fontSize: "15px" }}
                                ></i>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Change Health</td>
                              <td>5/1/2020 2:30PM</td>
                              <td>6/1/2020 1:50PM</td>
                              <td className="action-cell">
                                <i
                                  className="typcn typcn-delete-outline"
                                  style={{ fontSize: "15px" }}
                                ></i>
                                <i
                                  className="typcn typcn-upload-outline ms-4"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
        {alertSucStatus && (
          <SweetAlert
            title="Success!"
            success
            showCancel
            confirmBtnText="Return to Users Page"
            cancelBtnText="Continue"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="warning"
            onConfirm={() => {
              setAlertSucStatus(false)
              history.push("../../users")
            }}
            onCancel={() => {
              setAlertSucStatus(false)
            }}
          >
            User updated
          </SweetAlert>
        )}
      </Styles>
    </Container>
  )
}
export default UserEditPage
