import { signInReq } from "../../services/axios/axios.auth"
import holdTokens from "../../services/hold_tokens/holdTokenPair"

export async function signIn(e, b) {
  const email = b.email
  const password = b.password

  try {
    localStorage.clear()

    const ipResponse = await fetch("https://api.ipify.org/?format=json")
    const jsonIpRes = await ipResponse.json()

    const reqObj = { email, password, notes: jsonIpRes.ip }

    const response = await signInReq(reqObj);
    if (response === "Unauthorized") {
      return false
    }
    if (response.data && response.data.user && response.data.user.role.name !== "admin") {
      return false
    }
    if (response.status === 200) {
      const accessToken = response.data.tokens.accessToken

      const refreshToken = response.data.tokens.refreshToken
      await holdTokens.setTokens(accessToken, refreshToken)

      return true
    }


  } catch (error) {
    localStorage.clear()
    console.log(error)
    const reqObj = { email, password, notes: "" }

    const response = await signInReq(reqObj)
    console.log(response, "error response")
    if (response === "Unauthorized") {
      return false
    }
    if (response.data && response.data.user && response.data.user.email !== "admin@gmail.com") {
      return false
    }
    if (response.status === 200) {
      const accessToken = response.data.tokens.accessToken

      const refreshToken = response.data.tokens.refreshToken
      await holdTokens.setTokens(accessToken, refreshToken)

      return true
    }


  }
}
