import { usePagination, useSortBy, useTable } from "react-table"
import React from "react"
import { Container, Pagination, PaginationItem, PaginationLink } from "reactstrap"

export function Table({
                        columns,
                        data,
                        fetchData,
                        totalRecord,
                        loading,
                        onSort,
                        pageCount: controlledPageCount
                      }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualSortBy: true,
      manualPagination: true,
      pageCount: controlledPageCount
    },
    useSortBy,
    usePagination
  )
  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
    onSort(sortBy)
  }, [fetchData, pageIndex, pageSize, sortBy])

  // Render the UI for your table
  return (
    <>
      <Container fluid style={{ padding: 0 }}>
        <table {...getTableProps()}>

          <thead style={{ position: "sticky", top: 0, zIndex: 1001 }}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (

                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator // controlledPageCount * pageSize
              <td colSpan="10000">Loading...</td>
            ) : (
              <td colSpan="10000">
                Showing {page.length} of ~{totalRecord}{" "}
                results
              </td>
            )}
          </tr>
          </tbody>
        </table>
      </Container>
      <div className="pagination">
        <Pagination aria-label="Page navigation example" pagesize={10}
                    style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <PaginationItem>
            <PaginationLink onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink onClick={() => previousPage()} disabled={!canPreviousPage}>{"<"}</PaginationLink>
          </PaginationItem>
          <PaginationItem> <PaginationLink onClick={() => nextPage()}
                                           disabled={!canNextPage}>{">"}</PaginationLink></PaginationItem>
          <PaginationItem> <PaginationLink onClick={() => gotoPage(pageCount - 1)}
                                           disabled={!canNextPage}>{">>"}</PaginationLink></PaginationItem>

        </Pagination>
        <div>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}{" "}
          </strong>{"  "}
        </div>
        <div className="ms-1">
          {"   " + " | " + "Go to page: "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: "100px" }}
          />
        </div>
        {" "}
        <select
          value={pageSize}
          className="ms-1"
          style={{ height: "1.7rem" }}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

    </>
  )
}