import { usePagination, useSortBy, useTable } from "react-table"
import React from "react"
import { Container, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import moment from "moment"

import { getAllrolesReq } from "../../../services/axios/axios.roles"
import { getAllRolesReq } from "../../../services/axios/axios.roles"

//table columns
export const columnsContentRoles = [
  {
    Header: "ID",
    accessor: "_id"
  },
  {
    Header: "Title",
    accessor: "name"
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Status",
    accessor: "is_active"
  },
  {
    Header: "Permissions",
    accessor: "permissions"
  },
  {
    Header: "Date Created",
    accessor: "createdAt"
  },
  {
    Header: "Updated At",
    accessor: "updatedAt"
  },
  {
    Header: "Action",
    accessor: "action"
  }

]

///request for data
export const getRoles = async (skip, limit,
                               sortVal, realNameFilter,
                               activateFilterStatus, setActivateAlertStatus,
                               setDeactivateAlertStatus,setRoleIdForReactivate,
                               deleteRoleHandler, // setModalDeleteStatus,
                               history
) => {
  let results = await getAllRolesReq(
    skip, limit,
    sortVal, realNameFilter,
    activateFilterStatus
  )
  const rolesFromBack = results?.data?.roles ? results.data.roles : []
  const amount = results?.data.amount ? results.data.amount : 0
  const rolesToPush = []
  if (rolesFromBack && rolesFromBack?.length) {
    rolesFromBack.map(role => {
      let permissions = ""
      role.permissions.forEach(perm =>
        permissions = permissions ? permissions + " , " + perm.name.slice(0, 20) + "..." : perm.name.slice(0, 20) + "...")
      rolesToPush.push({
        _id: (
          <div
            className={role.is_active ? "" : "inactive"}
            key={"id-" + role._id}
          >
            {role._id.slice(0, 10) + "..."}
          </div>
        ),
        name: (
          <div
            className={role.is_active ? "" : "inactive"}
            key={"name-" + role._id}
          >
            {role?.name}
          </div>
        ),
        description: (
          <div
            className={role.is_active ? "" : "inactive"}
            key={"desc-" + role._id}>
            {role?.description.slice(0, 20) + "..."}
          </div>
        ),
        is_active: (
          <div
            className={role.is_active ? "" : "inactive"}
            key={"status-" + role._id}
          >
            {role?.is_active ? "active" : "inactive"}
          </div>

        ),
        permissions: (
          <div
            className={role.is_active ? "" : "inactive"}
            key={"perms-" + role._id}
          >
            {permissions}
          </div>

        ),
        createdAt: (
          <div
            className={role.is_active ? "" : "inactive"}
            key={"date-" + role._id}
          >
            {moment(role?.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
        updatedAt: (
          <div
            className={role.is_active ? "" : "inactive"}
            key={"date2-" + role._id}
          >
            {role?.updatedAt
              ? moment(role?.updatedAt).format("MM/DD/YYYY")
              : "-"}
          </div>
        ),

        action: (
      role.name!=='admin'&&    <div className="action-in-table" key={"action-" + role._id}>
        <i
          key={"ico1-" + role._id}
          data-id={role._id}
          className="typcn typcn-edit perm-action"
          onClick={() => history.push("../roles/" + role._id)}
        ></i>
        {role?.is_active ? (
          <i
            key={"ico2-" + role._id}
            data-id={role._id}
            onClick={e => {
              setDeactivateAlertStatus(true)
              setRoleIdForReactivate(role._id)
            }}
            className="typcn typcn-flash-outline perm-action"
          ></i>
        ) : (
          <i
            key={"ico3-" + role._id}
            className="typcn typcn-flash perm-action"
            data-id={role._id}
            onClick={e => {
              setRoleIdForReactivate(role._id)
              setActivateAlertStatus(true)
            }}
          ></i>
        )}
        <i
          key={"ico4-" + role._id}
          className="typcn typcn-delete-outline perm-action"
          data-id={role._id}
          onClick={(e) => {
            deleteRoleHandler(role._id)
          }}

        ></i>
      </div>
        )

      })
    })
  }
  return { rolesToPush, amount }
}