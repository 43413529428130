import { usePagination, useSortBy, useTable } from "react-table"
import React from "react"
import { Container, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import moment from "moment"

import { getAllSubsReq } from "../../../services/axios/axios.subs"

//table columns
export const columnsContent = [

  {
    Header: "ID",
    accessor: "_id"
  },
  {
    Header: "Title",
    accessor: "name"
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Status",
    accessor: "is_active"
  },
  {
    Header: "Permissions",
    accessor: "permissions"
  },
  {
    Header: "Date Created",
    accessor: "createdAt"
  },
  {
    Header: "Updated At",
    accessor: "updatedAt"
  },
  {
    Header: "Action",
    accessor: "action"
  },

]

///request for data
export const getSubs = async (skip, limit,
                              sortVal,realNameFilter,
                              activateFilterStatus,reactivatesubider,
                              setsubIdForDelete,setModalDeleteStatus,
                              history
) => {
  let results = await getAllSubsReq(
    skip, limit,
    sortVal,realNameFilter,
    activateFilterStatus
  )

  const subsFromBack = results?.data?.subs?results.data.subs:[]
  const amount = results?.data.amount?results.data.amount:0
  const subsToPush = []

  if (subsFromBack && subsFromBack?.length) {
    subsFromBack.map(sub => {
      let permissions =''
      sub.permissions.forEach(perm=>
        permissions=permissions?permissions+' , '+perm.name.slice(0,20)+'...':perm.name.slice(0,20)+'...')
      subsToPush.push({
        _id: (
          <div
            className={sub.is_active ? "" : "inactive"}
            key={"id-" + sub._id}
          >
            {sub._id.slice(0, 10) + '...'}
          </div>
        ),
        name: (
          <div
            className={sub.is_active ? "" : "inactive"}
            key={"name-" + sub._id}
          >
            {sub?.name}
          </div>
        ),
        description: (
          <div
            className={sub.is_active ? "" : "inactive"}
            key={"desc-" + sub._id}>
            {sub?.description.slice(0, 20)+'...'}
          </div>
        ),
        is_active: (
          <div
            className={sub.is_active ? "" : "inactive"}
            key={"status-" + sub._id}
          >
            {sub?.is_active?'active':'inactive'}
          </div>

        ),
        permissions: (
          <div
            className={sub.is_active ? "" : "inactive"}
            key={"perms-" + sub._id}
          >
            {permissions}
          </div>

        ),
        createdAt: (
          <div
            className={sub.is_active ? "" : "inactive"}
            key={"date-" + sub._id}
          >
            {moment(sub?.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
        updatedAt: (
          <div
            className={sub.is_active ? "" : "inactive"}
            key={"date2-" + sub._id}
          >
            {sub?.updatedAt
              ? moment(sub?.updatedAt).format("MM/DD/YYYY")
              : "-"}
          </div>
        ),

        action: (
          <div className="action-in-table" key={"action-" + sub._id}>
            <i
              key={"ico1-" + sub._id}
              data-id={sub._id}
              className="typcn typcn-edit perm-action"
              onClick={()=>history.push("../subscriptions/"+sub._id)}
            ></i>
            {!sub?.status ? (
              <i
                key={"ico2-" + sub._id}
                data-id={sub._id}
                onClick={e => {
                  reactivatesubider(sub._id,{status:sub.is_active})
                }}
                className="typcn typcn-flash-outline perm-action"
              ></i>
            ) : (
              <i
                key={"ico3-" + sub._id}
                className="typcn typcn-flash perm-action"
                data-id={sub._id}
                onClick={e => {
                  reactivatesubider(sub._id,{status:sub.is_active})
                }}
              ></i>
            )}
            <i
              key={"ico4-" + sub._id}
              className="typcn typcn-delete-outline perm-action"
              data-id={sub._id}
              onClick={(e) => {
                setsubIdForDelete(sub._id)
                setModalDeleteStatus(true)
              }}

            ></i>
          </div>
        )

      })
    })
  }
  return { subsToPush, amount }
}

