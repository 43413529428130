import axios from "axios"

import config from "../../config/index"

///get perms
export async function getPermsReq() {
  try {
    const response = await axios.get(config.axiosConnectionUrl+'perm/')
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

//get perms for table
export async function getPermsForTableReq(skip, limit,
                                          sortVal, realNameFilter,
                                          activateFilterStatus,typeFilterP) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+'perm/'
      + "table/query?skip="+skip
      +'&limit='+limit
      +'&sortid='+sortVal?.id
      +'&sortdesc='+sortVal?.desc
      +'&namefilter='+realNameFilter
      +'&activated='+activateFilterStatus
    +'&typefilter='+typeFilterP)
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

///get perms
export async function deletePermReq(id) {
  try {
    const response = await axios.delete(config.axiosConnectionUrl+'perm/' + id)
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}

///reactivate perms
export async function deactivatePermReq(id, status) {
  try {
    const response = await axios.put(
      config.axiosConnectionUrl+'perm/' + id + "/status/" + status
    )
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

///update perm
export async function updatePermReq(reqObj) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+'perm/', reqObj)
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

///create Perm (Beta)
export async function createDefPermReq(reqObj) {
  try {
    const response = await axios.post(config.axiosConnectionUrl+'perm/', reqObj)
    return response
  } catch (error) {
    return error.response.data.message
  }
}
