import defaultLogo from '../defaultCore/src/assets/images/logo-sm.png'
import lightHorLogo from '../defaultCore/src/assets/images/logo-light.png'
//import defaultLogo from '../defaultCore/src/assets/images/dancer.svg'

import store from "../store";
const prod = false;
const config = {
  axiosConnectionUrl: (prod) ? 'https://api-prd.dancersq.com/api/' : 'https://api-dev.dancersq.com/api/',
  breadCrumbsFirstTitle:'DancersQ',
  pageTitleAdditional: ' | DancersQ',
  footerMessage: ' DancersQ',
  loginPageProps: {
    title: 'DancersQ',
    messageH: 'Welcome to DancersQ',
    messageF: '',
    logo: defaultLogo
  },
  ResetPasswordPageProps: {
    title: 'DancerQ',
    messageH: 'Reset Password',
    messageF: '',
    logo: defaultLogo
  },
  reduxStore: store,
  leftSideBarLogo: lightHorLogo
}

export default config