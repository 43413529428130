import { Modal } from "reactstrap"
import { AvField, AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

export default  function EditDefaultPermModal({
                                                editModalShow,
                                                setEditModalShow,
                                                editFormInputs,
                                                permForEdit,
  updatePermission
                                                }){

  return (
      <Modal
    isOpen={editModalShow}
    toggle={() => {
      setEditModalShow(!editModalShow)
    }}
  >
    <div className="modal-header">
      <h5 className="modal-title mt-0" id="myModalLabel">
        Edit Default Permission
      </h5>
      <button
        type="button"
        onClick={() => {
          setEditModalShow(false)
        }}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <AvForm>
      <div className="modal-body">
        <div className="mb-3">
          <AvField
            name="name"
            label="Name  "
            onChange={editFormInputs}
            placeholder="Type name"
            type="text"
            errorMessage="Enter name"
            value={permForEdit.name}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="desc"
            onChange={editFormInputs}
            label="Description  "
            placeholder="Type description"
            type="text"
            value={permForEdit.description}
            validate={{
              required: { value: true },
            }}
          />
        </div>

        <div className="mb-3">
          <AvField
            name="type"
            onChange={editFormInputs}
            label="Pick a type  "
            //   placeholder="Choose perm"
            value={permForEdit.type}
            type="select"
            errorMessage="Choose Type"
            validate={{
              required: { value: true },
            }}
          >
            <option></option> <option value="role">Role</option>{" "}
            <option value="subscription">Subscription</option>{" "}
          </AvField>
        </div>
        <div className="mb-3">
          <AvRadioGroup
            inline
            name="usage-type"
            label="Usage type"
            required
            className="radio"
            onChange={editFormInputs}
            value={permForEdit.is_internal}
          >
            <AvRadio label="Internal" value="true" />
            <AvRadio label="External" value="false" />
          </AvRadioGroup>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setEditModalShow(!editModalShow)
          }}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="submit"
          className="btn btn-primary waves-effect waves-light"
          onClick={()=>{updatePermission(permForEdit)}}
        >
          Update Permission
        </button>
      </div>
    </AvForm>
  </Modal>


  )
}