import {
  TabContent,
  TabPane,
  Row,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardFooter,
  CardBody,
  Alert,
  Card,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory, useParams } from "react-router-dom"


import { getPermsReq } from "../../../../services/axios/axios.default_permissions"
import { getDynPermReq, updateDynPerm } from "../../../../services/axios/axios.perm_groups"
import { setNavValues } from "../../../../store/nav_breadcrumbs/actions"
import SmallSwitch from "../../../../components/shared/smallSwitch"
import { Styles } from "../../user/user_table"
import config from '../../../../config/index'

const GroupPermEditPage = () => {
  //setState block=======================================================================
  //role constructor input states
  const [groupName, setGroupName] = useState("")
  const [permType, setPermType] = useState("")
const [groupDesc,setGroupDesc] = useState("")
  //data from backend
  const [defPermsStatic, setDefPermsStatic] = useState([])
  const [defPermsToRender, setDefPermsToRender] = useState([])
  const [permsArr, setPermsArr] = useState([])
  ///other
  const [activeTab, setActiveTab] = useState("1")
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertStatusWarn, setalertStatusWarn] = useState(false)
  const [alertText, setalertText] = useState("")
  const [permGroupIDforUpdate, setPermGroupIDforUpdate] = useState("")

  const history = useHistory()
const params = useParams()
  //on change handlers=========================================================================
  const nameOnChange = e => {
    setGroupName(e.target.value)
  }

  const descOnChange = e =>{
    setGroupDesc(e.target.value)
  }

  const typeOnChange = e => {
    const type = e.target.value
    setPermType(type)
    const permsWithChecked = []
    permsArr.map(perm => {
      perm.isChecked = false
      if (perm.type === type) {
        permsWithChecked.push(perm)
      }
    })
    setActiveTab("2")
    setDefPermsToRender(permsWithChecked)
    setDefPermsStatic(permsWithChecked)
  }

  const searchFilterCreate = e => {
    const searchString = e.target.value
    if (searchString === "") {
      setDefPermsToRender(defPermsStatic)
      return
    }

    const filteredArr = defPermsStatic.filter(perm =>
      perm?.name.toLowerCase().includes(searchString.toLowerCase())
    )
    setDefPermsToRender(filteredArr)
  }

  const permsOnChange = (value, id, isChecked) => {
    const filteredArr = defPermsToRender
    const elementOnCheck = filteredArr.find(perm => perm._id === id)
    elementOnCheck.isChecked = !isChecked
    setDefPermsToRender([...filteredArr])
    // setMagicRender(!magicRender)
  }
  ///async handlers
  //on save modal handler
  const saveChanges = async () => {
    if (!groupName || !permType) {
      setActiveTab("1")
      return
    }
    const permArrToSend = []
    defPermsToRender.map(perm => {
      if (perm.isChecked) {
        permArrToSend.push(perm._id)
      }
    })
    const reqObj = {
      name: groupName,
      _id: permGroupIDforUpdate,
      type: permType,
      description:groupDesc,
      permissionID: permArrToSend,
    }
    const response = await updateDynPerm(reqObj)
    if (response.data === "succesfully updated") {
      setActiveTab("1")
      setAlertStatus(true)
    } else {
      setalertStatusWarn(true)
      setalertText(
        response?.data?.message
          ? response?.data?.message
          : "Something went wrong"
      )
      setTimeout(() => {
        setalertStatusWarn(false)
      }, 5000)
    }
  }
  /////async block===========================================================

  ///get default perms
  const getPerms = async () => {
    const permsRes = await getPermsReq()
    const perms = permsRes?.data
    if (perms?.length) {
      setPermsArr(perms)
    }
    const permission = await getDynPermReq(params.id)
    const state = permission.data
    const currentDefPerms = state.permissionID

    setGroupName(state.name)
    setPermType(state.type)
    setGroupDesc(state.description)
    setPermGroupIDforUpdate(state._id)
    const permsWithChecked = []
    perms.map(perm => {
      const matchPerm = currentDefPerms?.find(
        defPerm => defPerm._id === perm._id
      )
      perm.isChecked = !!matchPerm
      if (perm.type === state.type) {
        permsWithChecked.push(perm)
      }
    })
    setDefPermsToRender(permsWithChecked)
  }

  const unassignedPerm = defPermsToRender?.map((perm, index) => {
    if (!perm.isChecked && perm.is_active) {
      return (
        <Row className="perm-check" key={"right-switch" + perm._id}>
          <SmallSwitch
            perm={perm}
            permsOnChange={permsOnChange}
          />
        </Row>
      )
    }
  })

  //useEffect block
  useEffect(() => {
    getPerms()
    config.reduxStore.dispatch(setNavValues({mainTitle:config.breadCrumbsFirstTitle,title1:'Permission Groups',title2:'Edit'}))

  }, [])

  //inner components

  return (
    <Container className="p-0 user-main-wrapper" fluid>
      <Styles className=" styles-inner-pages-wrapper">
        <Container fluid className="edit-user-wrapper hide-scrollbar">
          <AvForm>
              <div className="modal-body">
                <Col className="user-data-inputs">
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("1")
                        }}
                      >
                        <span className="d-none d-sm-block">General</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          Permissions Assignment
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <Card>
                            <CardBody className="mb-0">
                              <div className="mb-3">
                                <AvField
                                  name="name"
                                  label="Name  "
                                  value={groupName}
                                  onChange={nameOnChange}
                                  placeholder="Add name"
                                  type="text"
                                  errorMessage="Add name"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                              <div className="mb-3">
                                <AvField
                                  name="description"
                                  label="Description"
                                  value={groupDesc}
                                  onChange={descOnChange}
                                  placeholder="Add description (not required)"
                                  type="text"
                                />
                              </div>
                              <div className="mb-3">
                                <AvField
                                  name="type"
                                  onChange={typeOnChange}
                                  value={permType}
                                  label="Pick a type  "
                                  //   placeholder="Choose perm"
                                  type="select"
                                  errorMessage="Choose Type"
                                  validate={{
                                    required: { value: true },
                                  }}
                                >
                                  <option></option>{" "}
                                  <option value="role">Role</option>{" "}
                                  <option value="subscription">
                                    Subscription
                                  </option>{" "}
                                </AvField>
                              </div>
                            </CardBody>
                            <CardFooter>
                              <button
                                type="button"
                                onClick={() => {
                                  history.push("../permission-groups/")
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                              >
                                Back
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={saveChanges}
                              >
                                Update Permission Group
                              </button>
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody className="assign-content">
                              <Row className="perms-check">
                                <Col sm={12}>
                                  <div className="mb-3">
                                    <AvField
                                      name="searchFilterCreate"
                                      onChange={searchFilterCreate}
                                      // value={userDataToRender.fullname}
                                      label="Search by name  "
                                      type="text"
                                    />
                                  </div>
                                </Col>
                                {!permType && (
                                  <Col sm={12}>
                                    <h5>You should pick a type first</h5>
                                  </Col>
                                )}
                                {!defPermsToRender?.length && (
                                  <Col sm={12}>
                                    <h5>No available permissions with type 'subscription'. You create one <span style={{color: 'blue',cursor: "pointer" }}
                                                                                                                onClick={()=>{
                                                                                                                  history.push({pathname:  "../default-permissions", state:{isModalRequired:true} }
                                                                                                                  )}}>here</span></h5>
                                  </Col>
                                )}
                                {defPermsToRender &&
                                  !!defPermsToRender?.length && (
                                    <Col sm={6} className="unassigned-col">
                                      <h5 className="ms-4">
                                        Assigned permissions
                                      </h5>
                                      <br />
                                      {defPermsToRender?.map((perm, index) => {
                                        if (perm.isChecked) {
                                          return (
                                            <Row
                                              className="perm-check"
                                              key={"left-switch" + perm._id}
                                            >
                                              <SmallSwitch
                                                key={
                                                  "left-small-switch" + perm._id
                                                }
                                                perm={perm}
                                                permsOnChange={permsOnChange}
                                              />
                                            </Row>
                                          )
                                        }
                                      })}
                                    </Col>
                                  )}
                                {defPermsToRender &&
                                  !!defPermsToRender?.length && (
                                    <Col sm={6} className="assigned-col">
                                      <h5 className="ms-4">
                                        Unassigned permissions
                                      </h5>
                                      <br />
                                      {unassignedPerm[0] === undefined &&unassignedPerm.length<2? <h5>No available</h5> : unassignedPerm}
                                    </Col>
                                  )}
                              </Row>
                            </CardBody>




            <CardFooter>
              <button
                type="button"
                onClick={() => {
                  history.push("../permission-groups/")
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                onClick={saveChanges}
              >
                Update Permission Group
              </button>
            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </div>

          </AvForm>
      {alertStatus ? (
        <SweetAlert
          className="alert-perm-group"
          title="Success!"
          success
          isAnimation={true}
          showCancel
          confirmBtnText="Return"
          cancelBtnText="Continue"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="primary"
          onConfirm={() => {
            history.push("../permission-groups")
          }}
          onCancel={() => {
            setAlertStatus(false)
          }}
        >
          Permission Group updated!
        </SweetAlert>
      ) : null}
      {alertStatusWarn && (
        <Alert color="warning" style={{ position: "absolute", bottom: 50 }}>
          <strong>Oops!</strong> {alertText}
        </Alert>
      )}
        </Container>
      </Styles>
    </Container>
  )
}

export default GroupPermEditPage
