import React from "react"
import moment from "moment"


import { getPermsForTableReq } from "../../../services/axios/axios.default_permissions"

//table columns
export const columnsContentDefaultPermissions = [
  {
    Header: "ID",
    accessor: "_id"
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Type",
    accessor: "type"
  },
  {
    Header: "Description",
    accessor: "description"
  },

  {
    Header: "Status",
    accessor: "is_active"
  },

  {
    Header: "Date Created",
    accessor: "createdAt"
  },
  {
    Header: "Updated At",
    accessor: "updatedAt"
  },
  {
    Header: "Action",
    accessor: "action"
  }

]

///request for data
export const getPerms = async (skip, limit,
                               sortVal, realNameFilter,
                               activateFilterStatus, reactivatePermission,
                               setModalShow,editPermission,
                               deletePermission,typeFilterP,
                               history
) => {
  let response = await getPermsForTableReq(
    skip, limit,
    sortVal, realNameFilter,
    activateFilterStatus,typeFilterP
  )

  const permsFromBack = response?.data?.permissions && response.data.permissions.length ? response.data.permissions : []
  const amount = response?.data.amount ? response.data.amount : 0
  const permsToPush = []
  if (permsFromBack && permsFromBack?.length) {
    permsFromBack.map(perm => {

      permsToPush.push({
        _id: (
          <div
            className={perm.is_active ? "" : "inactive"}
            key={"id-" + perm._id}
          >
            {perm._id.slice(0, 10) + "..."}
          </div>
        ),
        name: (
          <div
            className={perm.is_active ? "" : "inactive"}
            key={"name-" + perm._id}
          >
            {perm?.name}
          </div>
        ),
        type: (
          <div
            className={perm.is_active ? "" : "inactive"}
            key={"perms-" + perm._id}
          >
            {perm.type}
          </div>

        ),
        description: (
          <div
            className={perm.is_active ? "" : "inactive"}
            key={"desc-" + perm._id}>
            {perm.description}
          </div>
        ),

        is_active: (
          <div
            className={perm.is_active ? "" : "inactive"}
            key={"status-" + perm._id}
          >
            {perm?.is_active ? "active" : "inactive"}
          </div>

        ),

        createdAt: (
          <div
            className={perm.is_active ? "" : "inactive"}
            key={"date-" + perm._id}
          >
            {moment(perm?.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
        updatedAt: (
          <div
            className={perm.is_active ? "" : "inactive"}
            key={"date2-" + perm._id}
          >
            {perm?.updatedAt
              ? moment(perm?.updatedAt).format("MM/DD/YYYY")
              : "-"}
          </div>
        ),

        action: (
          <div className="action-in-table" key={"action-" + perm._id}>
            <i
              key={"ico1-" + perm._id}
              data-id={perm._id}
              className="typcn typcn-edit perm-action"
              onClick={() => editPermission(perm._id, permsFromBack)}
            ></i>
            {perm?.is_active ? (
              <i
                key={"ico2-" + perm._id}
                data-id={perm._id}
                onClick={(e) => {
                  reactivatePermission(e,perm._id,'true')
                }}
                className="typcn typcn-flash-outline perm-action"
              ></i>
            ) : (
              <i
                key={"ico3-" + perm._id}
                className="typcn typcn-flash perm-action"
                data-id={perm._id}
                onClick={e => {
                  reactivatePermission(e,perm._id,'false')
                }}
              ></i>
            )}
            <i
              key={"ico4-" + perm._id}
              className="typcn typcn-delete-outline perm-action"
              data-id={perm._id}
              onClick={(e) => {
                deletePermission(e,perm._id)
              }}

            ></i>
          </div>
        )

      })
    })
  }
  return { permsToPush, amount }
}