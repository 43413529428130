import { useState } from "react"
import { Col, Row } from "reactstrap"
import Switch from "react-switch"

const SmallSwitch = ({ perm, permsOnChange }) => {
  const [switch1, setswitch1] = useState(perm.isChecked)
  const [ttop, setttop] = useState(false)
  const Offsymbol2 = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol2 = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  return (
    <Row fluid className="switch-perm-block mb-2">
      <Col sm={2}>
        <Switch
          id={perm._id}
          onChange={value => {
            permsOnChange(value, perm._id, perm.isChecked)
          }}
          uncheckedIcon={<Offsymbol2 />}
          checkedIcon={<OnSymbol2 />}
          onColor="#626ed4"
          checked={switch1}
        />
      </Col>
      <Col
        className="ms-0"
        sm={8}
        id={"TooltipLeft" + perm._id}
        onMouseEnter={() => {
          setttop(true)
        }}
        onMouseLeave={() => {
          setttop(false)
        }}
      >
        {perm.name}
      </Col>
      {ttop && (
        <Col sm={12} style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          {perm.description}
        </Col>
      )}
    </Row>
  )
}
export default SmallSwitch