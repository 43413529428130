import axios from "axios"

import config from "../../config/index"



export async function getSongsForTableReq(skip: any, limit: any,
    sortVal: any, realNameFilter: any,
    activateFilterStatus: any) {
    try {
        const response = await axios.get(config.axiosConnectionUrl + 'songs/'
            + "table/query?skip=" + skip
            + '&limit=' + limit
            + '&sortid=' + sortVal?.id
            + '&sortdesc=' + sortVal?.desc
            + '&namefilter=' + realNameFilter
            + '&activated=' + activateFilterStatus)
        return response
    } catch (error) {
        const err:any = error;
        console.log(err)
        return err.response && err.response?.data?.message;
    }
}

//reactivate Songs
export async function deactivateSongReq(id: string) {
    try {
        const response = await axios.put(
            config.axiosConnectionUrl + 'songs/' + "reactivate/" + id
        )
        return response
    } catch (error) { 
        const err:any = error;
        console.log(err)
        return err.response && err.response?.data?.message;
    }
}

//delete Songs
export async function deleteSongReq(id: string) {
    try {
        const response = await axios.delete(
            config.axiosConnectionUrl + 'songs/'  + id
        )
        return response
    } catch (error) { 
        const err:any = error;
        console.log(err)
        return err.response && err.response?.data?.message;
    }
}

//get Songs
export async function getSongReq(id: string) {
    try {
        const response = await axios.get(
            config.axiosConnectionUrl + 'songs/'  + id
        )
        return response
    } catch (error) { 
        const err:any = error;
        console.log(err)
        return err.response && err.response?.data?.message;
    }
}


//get Songs
export async function updateSongReq(id: string, payload: any) {
    try {
        const response = await axios.patch(
            config.axiosConnectionUrl + 'songs/'  + id, payload
        )
        return response
    } catch (error) { 
        const err:any = error;
        console.log(err)
        return err.response && err.response?.data?.message;
    }
}




//create Songs
export async function createSongReq(payload: any) {
    try {
        const response = await axios.post(
            config.axiosConnectionUrl + 'songs', payload
        )
        return response
    } catch (error) { 
        const err:any = error;
        console.log(err)
        return err.response && err.response?.data?.message;
    }
}
