import React, { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'reactstrap';
import { Styles } from '../default/user/user_table';
import config from "../../config"
import { setNavValues } from "../../store/nav_breadcrumbs/actions"
import { columnsContentSongs, getSongs } from './songs.table';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useThrottle from '../../services/shared_handlers/debouncer';
import { setSortStateAc } from '../../store/table/sort/actions';
import { Offsymbol, OnSymbol } from '../../components/shared/switchSymbolsDefault';
import Switch from "react-switch"
import { Table } from '../../components/shared/reactTable';
import SweetAlert from 'react-bootstrap-sweetalert';
import { deactivateSongReq, deleteSongReq } from '../../services/axios/axios.songs';


export default function Songs() {
    const [magicRender, setMagicRender] = useState(false)
    const [alertStatus, setAlertStatus] = useState(false)
    const [alertStatusWarn, setAlerWarnStatus] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [deleteAlertStatus, setDeleteAlertStatus] = useState(false)
    const [data, setData] = React.useState<any[]>([])
    const [pageCount, setPageCount] = React.useState(0)
    const [totalRecord, setTotalRecord] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [realNameFilter, setRealNameFilter] = useState("")
    const [activateFilterStatus, setActivateFilterStatus] = useState(false)
    const [songIdForReactivate, setsongIdForReactivate] = useState('')
    const [songIdForDelete, setsongIdForDelete] = useState('')
    const [activateAlertStatus, setActivateAlertStatus] = useState(false)
    const [deactivateAlertStatus, setDeactivateAlertStatus] = useState(false)

    const history = useHistory()
    const sortVal = useSelector((state: any) => state.sort.sortBy)
    const [debounce] = useThrottle(1000)

    //dispatch sorting
    const onSortedChange = React.useCallback((sortBy) => {
        config.reduxStore.dispatch(setSortStateAc(sortBy[0]))
    }, [])

    async function reactivateSong() {
        const response = await deactivateSongReq(songIdForReactivate)
        if (response?.data.message === "success") {
            setActivateAlertStatus(false)
            setDeactivateAlertStatus(false)
            if (response?.data.result) {
                setAlertStatus(true)
                setAlertText("Song activated")
            } else {

                setAlerWarnStatus(true)
                setAlertText("Song deactivated")
            }
            setMagicRender(!magicRender)
        } else {
            setAlerWarnStatus(true)
            setAlertText('Unknown error. please contact administrator')
            setActivateAlertStatus(false)
            setDeactivateAlertStatus(false)
        }
        setMagicRender(!magicRender)
        setTimeout(() => {
            setAlerWarnStatus(false)
            setAlertStatus(false)
        }, 2000)
    }

    //fill table
    const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
        setLoading(true)
        const startRow = pageSize * pageIndex
        const {
            groupsToPush,
            amount
        } = await getSongs(
            startRow, pageSize,
            sortVal, realNameFilter,
            activateFilterStatus, setActivateAlertStatus, setDeactivateAlertStatus,
            setsongIdForReactivate, setsongIdForDelete,
            setDeleteAlertStatus, history
        )
        setData(groupsToPush)
        setPageCount(Math.ceil(amount / pageSize));
        setTotalRecord(amount);
        setLoading(false)
    }, [
        sortVal, realNameFilter,
        activateFilterStatus, magicRender
    ])
    //filter handlers
    const nameFilter = (e: any) => {
        debounce(() => () => setRealNameFilter(e.target.value))
    }

    ///clear all filter
    const resetFilters = () => {
        setRealNameFilter("")
        setActivateFilterStatus(false)
    }


    async function deleteSong() {
        const response = await deleteSongReq(songIdForDelete)
        if (response?.data?._id) {
            setAlertText("Song " + response?.data?._id + " deleted")
            setMagicRender(!magicRender)
        } else {
            setAlertText("Unknown error. please contact administrator")
        }
        setDeleteAlertStatus(false)
        setAlerWarnStatus(true)

        setTimeout(() => {
            setAlerWarnStatus(false)
        }, 2000)
        setMagicRender(!magicRender)
    }


    //useeffectsect
    useEffect(() => {
        config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title2: 'Songs', title1: '' }))
    }, [])

    return (
        <Container fluid className="providers-main-wrapper">
            {deleteAlertStatus ? (
                <SweetAlert
                    title="Attention"
                    success={false}
                    showCancel
                    confirmBtnText="No"
                    cancelBtnText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="warning"
                    onConfirm={() => {
                        setDeleteAlertStatus(false)
                    }}
                    onCancel={() => {
                        // deleteGroup(permGroupIdForDelete)
                        deleteSong();
                    }}
                >
                    Are you sure want to delete this Song?
                    <br />
                    Deletion cannot be reverted.
                </SweetAlert>
            ) : null}
            {deactivateAlertStatus ? (
                <SweetAlert
                    title="Attention"
                    success={false}
                    showCancel
                    confirmBtnText="No"
                    cancelBtnText="Deactivate"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="warning"
                    onConfirm={() => {
                        setDeactivateAlertStatus(false)
                    }}
                    onCancel={() => {
                        // reactivateGroup(permGroupIdForReactivate)
                        reactivateSong();
                    }}
                >
                    Are you sure want to deactivate this song?
                </SweetAlert>
            ) : null}
            {activateAlertStatus ? (
                <SweetAlert
                    title="Attention"
                    success={false}
                    showCancel
                    confirmBtnText="Activate"
                    cancelBtnText="No"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="warning"
                    onConfirm={() => {
                        // reactivateGroup(permGroupIdForReactivate)
                        reactivateSong();
                    }}
                    onCancel={() => {
                        setActivateAlertStatus(false)
                    }}
                >
                    Are you sure want to activate this song?
                </SweetAlert>
            ) : null}
            <Styles className="provider-white-card">
                <div className="card-title-desc user-table-filter-block mb-3">
                    <div>
                        <div className="col-md-12">
                            <input
                                // ref={inputTitle}
                                className="form-control sub-inputs"
                                placeholder="Search by title"
                                type="text"
                                onChange={nameFilter}
                            />
                        </div>
                    </div>
                    <div className="clear-button me-5">
                        <Button
                            color="secondary"
                            className="waves-effect waves-light"
                            onClick={resetFilters}
                        >
                            Clear Filters
                        </Button>
                    </div>
                    <div className="active-add-block">
                        <div className="switch-component pb-1">
                            <div className="mb-1">Show Deactivated</div>
                            <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#626ed4"
                                value={activateFilterStatus.toString()}
                                onClick={e => {
                                }}
                                onChange={e => {
                                    setActivateFilterStatus(!activateFilterStatus)
                                }}
                                checked={activateFilterStatus}
                            />
                        </div>
                        <i
                            onClick={() => {
                                history.push("/songs/create")
                            }}
                            className="mdi mdi-bookmark-plus add-icon sub-add-btn"
                        ></i>
                    </div>
                </div>
                <Table
                    columns={columnsContentSongs}
                    data={data}
                    pageCount={pageCount}
                    totalRecord={totalRecord}
                    fetchData={fetchData}
                    loading={loading}
                    onSort={onSortedChange}
                />


            </Styles>
            {alertStatus && (
                <div className="alert-wrapper">
                    <Alert color="success" className="bg-success text-white success-custom alert-card">
                        <strong>Well done!</strong> {alertText}
                    </Alert>
                </div>
            )}
            {alertStatusWarn && (
                <div className="alert-wrapper">
                    <Alert color="warning" className="bg-warning text-white success-custom alert-card">
                        <strong>Oops!</strong> {alertText}
                    </Alert>
                </div>
            )}
        </Container>
    )
}