import axios from "axios"

import config from "../../config/index"

export async function changeRoleReq(roleID, userID) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+ 'role/', {
      role: roleID
    })
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

//get roles list
export async function getRolesReq() {
  try {
    const response = await axios.get(config.axiosConnectionUrl+ 'role/')
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message
  }
}

//get role by id
export async function getRoleReq(id) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+ 'role/')
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message
  }
}

//get roles with server side actions
export async function getAllRolesReq(skip, limit,
                                     sortVal, realNameFilter,
                                     activateFilterStatus) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+ 'role/'
      + "table/query?skip=" + skip
      + "&limit=" + limit
      + "&sortid=" + sortVal?.id
      + "&sortdesc=" + sortVal?.desc
      + "&namefilter=" + realNameFilter
      + "&activated=" + activateFilterStatus
    )

    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message
  }
}

//create new role
export async function createRoleReq(reqObj) {
  try {
    const response = await axios.post(config.axiosConnectionUrl+ 'role/', reqObj)
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

//update role
export async function updateRoleReq(id, reqObj) {
  try {
    const response = await axios.put(
      config.axios.role_string + "update/" + id,
      reqObj
    )
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}

//reactivateRole
export async function deactivateRolReq(id) {
  try {
    const response = await axios.put(
      config.axiosConnectionUrl+ 'role/'+ "reactivate/" + id
    )
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}

///delete
export async function deleteRoleReq(id) {
  try {
    const response = await axios.delete(config.axiosConnectionUrl+ 'role/' + id)
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}
