import axios from "axios"

import config from "../../config/index"

///get subs for drop down
export async function getSubsReq() {
  try {
    const response = await axios.get(config.axiosConnectionUrl+ 'sub/')
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

//for table
export async function getAllSubsReq(skip,limit,sortVal,realNameFilter,activateFilterStatus) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+ 'sub/'
      + "table/query?skip=" + skip
      + "&limit=" + limit
      + "&sortid=" + sortVal?.id
      + "&sortdesc=" + sortVal?.desc
      + "&namefilter=" + realNameFilter
      + "&activated=" + activateFilterStatus
    )
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

export async function createSubReq(sub) {
  try {
    const response = await axios.post(config.axiosConnectionUrl+ 'sub/',sub)
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }

}

export async function reactivateSubReq(id,status) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+ 'sub/' +'status/'+id , status)
    return response
  }catch (e) {
    console.log(error)
  }
}

//update sub
export async function updateSubReq(id,sub) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+ 'sub/' +id ,sub)
    return response
  }catch (e) {
    return e
  }
}
export async function deleteSubReq(id) {
  try {
    const response = await axios.delete(config.axiosConnectionUrl+ 'sub/'+id )
    return response
  }catch (e) {
    return e
  }
}

//for one sub - sub edit page
export async function getSubReq(id) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+ 'sub/' +id )
    return response
  }catch (e) {
    return e
  }
}