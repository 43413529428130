import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, Col, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import config from '../../../config';
import { setNavValues } from '../../../store/nav_breadcrumbs/actions';
import { Styles } from '../../default/user/user_table';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createSongReq, getSongReq, updateSongReq } from '../../../services/axios/axios.songs';
import AsyncSelect from 'react-select/async';
import useThrottle from '../../../services/shared_handlers/debouncer';
import { getAllUsersTestReq } from '../../../services/axios/axios.user';
import "./songs.create.scss"

const songSchema = Yup.object().shape({
    name: Yup.string().required("Name is Required"),
    link: Yup.string().required("Link is Required"),
    platform: Yup.string().oneOf(['android', 'ios']).required("Platform is Required"),
    userID: Yup.string().required("user is Required"),

});


export default function SongCreate() {
    const [isEdit, setIsEdit] = useState(false);
    const [editdata, setEditdata] = useState({});
    const [user, setUser] = useState({});

    const [activeTab, setActiveTab] = useState("1")
    const [alertStatus, setAlertStatus] = useState(false)
    const history = useHistory();
    const [debounce] = useThrottle(1000);
    const params = useParams<{ id: string }>();

    const getValue = async (id: string) => {
        try {
            let { data } = await getSongReq(id);
            const payload = data.song[0];
            if (payload) {
                setEditdata({ markers: payload.markers, name: payload.name, link: payload.link, s3Path: payload.s3Path, platform: payload.platform, userID: payload.userID._id });
                setUser({ value: payload.userID._id, label: payload.userID.fullName || payload.userID.firstName + ' ' + payload.userID.lastName });
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        if (params && params.id) {
            setIsEdit(true);
            getValue(params.id);
        }
    }, [params]);


    const onsubmit = async (values: any) => {
        try {
            if (isEdit) {
                const response = await updateSongReq(params.id, { ...values })
                if (response?.data && response?.data?.updatedSong && response?.data?.updatedSong?.is_active) {
                    setActiveTab("1")
                    setAlertStatus(true)
                }
            } else {
                const response = await createSongReq({ ...values, markers: [] })
                if (response?.data && response?.data?.data && response?.data?.data?.is_active) {
                    setActiveTab("1")
                    setAlertStatus(true)
                }
            }
        } catch (err) {

        }
    }

    const loadOptions = async (
        inputValue: any,
        callback: (options: any[]) => void
    ) => {
        const results = await getAllUsersTestReq(
            0, 100,
            '', inputValue,
            '', '',
            false)
        const usersFromBack = results?.data.usersArr || [];
        callback(usersFromBack.map((user: any) => {
            return {
                label: user.name,
                value: user.id
            }
        }))
    };

    const formatMarkerTime = (time: number) => {
        const minutes = Math.floor(time / 60) < 10
            ? '0' + Math.floor(time / 60)
            : '' + Math.floor(time / 60);
        const seconds = Math.floor(time % 60) < 10
            ? '0' + Math.floor(time % 60)
            : '' + Math.floor(time % 60);
        return `${minutes}:${seconds}`;
    }


    useEffect(() => {
        config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title1: "Songs", title2: (isEdit) ? "Edit" : "Create" }));
    }, [isEdit])

    return (
        <Container className="p-0 user-main-wrapper" fluid>
            <Styles className=" styles-inner-pages-wrapper">
                <Container fluid className="edit-user-wrapper hide-scrollbar">
                    <div className="modal-body">
                        <Col className="user-data-inputs">
                            <Nav tabs className="nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={activeTab === "1" ? "active" : ""}
                                        onClick={() => {
                                            setActiveTab("1")
                                        }}
                                    >
                                        <span className="d-none d-sm-block">General</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <Card>
                                                <Formik
                                                    initialValues={{ ...{ name: '', link: '', platform: '', userID: '',s3Path: '', markers: [] }, ...editdata }}
                                                    enableReinitialize={isEdit}
                                                    validationSchema={songSchema}
                                                    onSubmit={(values, actions) => {
                                                        // console.log('values', values)
                                                        onsubmit(values);
                                                    }}
                                                >
                                                    {
                                                        props => (<React.Fragment>
                                                            {(() => {
                                                                // console.log(props)
                                                            })()}
                                                            <CardBody className="mb-0">
                                                                <form onSubmit={props.handleSubmit}>
                                                                    <FormGroup>
                                                                        <Label for="name">Name</Label>
                                                                        <Input
                                                                            name="name"
                                                                            id="name"
                                                                            placeholder="Add name"
                                                                            onChange={props.handleChange}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.name}
                                                                            type="text"
                                                                            invalid={(!!props.errors.name)}
                                                                        />
                                                                        {props.errors.name && <div className="invalid-feedback">{props.errors.name}</div>}
                                                                    </FormGroup>
                                                                    <br />
                                                                    <FormGroup>
                                                                        <Label for="link">Link</Label>
                                                                        <Input
                                                                            name="link"
                                                                            id="link"
                                                                            placeholder="Add Link"
                                                                            onChange={props.handleChange}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.link}
                                                                            type="text"
                                                                            invalid={(!!props.errors.link)}
                                                                        />
                                                                        {props.errors.link && <div className="invalid-feedback">{props.errors.link}</div>}
                                                                    </FormGroup>
                                                                    <br />
                                                                    { props.values.s3Path && 
                                                                         <FormGroup>
                                                                         <Label for="s3Path">S3 Path</Label>
                                                                         <Input
                                                                             name="s3Path"
                                                                             id="s3Path"
                                                                             placeholder="Add S3 Path"
                                                                             onChange={props.handleChange}
                                                                             onBlur={props.handleBlur}
                                                                             value={props.values.s3Path}
                                                                             type="text"
                                                                             invalid={(!!props.errors.s3Path)}
                                                                         />
                                                                         {props.errors.s3Path && <div className="invalid-feedback">{props.errors.s3Path}</div>}
                                                                     </FormGroup>
                                                                    }
                                                                    {props.values.markers.length > 0 &&
                                                                        <React.Fragment>
                                                                            <br />
                                                                            <FormGroup>
                                                                                <Label for="link">Markers</Label>
                                                                                <div className="markersCont">
                                                                                    {
                                                                                        props.values.markers.map((marker, key) => {
                                                                                            return (
                                                                                                <div className="box" key={key}>
                                                                                                    <div className="marker">
                                                                                                        <div className="icon" onClick={() => {
                                                                                                            const values = props.values;
                                                                                                            values.markers.splice(key, 1);
                                                                                                            props.setValues({
                                                                                                                ...values
                                                                                                            })
                                                                                                        }}>
                                                                                                            <i className="mdi mdi-close-circle-outline"></i>
                                                                                                        </div>
                                                                                                        <div _ngcontent-jrw-c141="" className="marker-number"> {formatMarkerTime(marker)} </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </FormGroup>
                                                                        </React.Fragment>
                                                                    }
                                                                    <br />
                                                                    <FormGroup>
                                                                        <Label for="name">Platform</Label>
                                                                        <Input
                                                                            name="platform"
                                                                            id="platform"
                                                                            placeholder="Add Platform"
                                                                            onChange={props.handleChange}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.platform}
                                                                            type="select"
                                                                            invalid={(!!props.errors.platform)}
                                                                        >
                                                                            <option></option>
                                                                            <option>android</option>
                                                                            <option>ios</option>
                                                                        </Input>
                                                                        {props.errors.platform && <div className="invalid-feedback">{props.errors.platform}</div>}
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label for="userID">User</Label>
                                                                        <AsyncSelect
                                                                            cacheOptions
                                                                            loadOptions={(...e: any[]) => {
                                                                                debounce(() => () => loadOptions(e[0], e[1]))
                                                                            }}
                                                                            value={user}
                                                                            onChange={(e: any) => {
                                                                                props.setFieldValue('userID', e.value, true);
                                                                                setUser(e);
                                                                            }}
                                                                            defaultOptions
                                                                        />
                                                                        {props.errors.userID && <div className="invalid-feedback">{props.errors.userID}</div>}
                                                                    </FormGroup>
                                                                </form>
                                                            </CardBody>
                                                            <CardFooter>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        history.push("/songs")
                                                                    }}
                                                                    className="btn btn-secondary waves-effect"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Back
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    onClick={() => { props.handleSubmit() }}
                                                                    className="btn btn-primary waves-effect waves-light"
                                                                >
                                                                    {isEdit ? 'Update Song' : 'Create Song'}
                                                                </button>
                                                            </CardFooter>
                                                        </React.Fragment>)
                                                    }
                                                </Formik>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </div>



                    {alertStatus ? (
                        <SweetAlert
                            title="Success!"
                            success
                            showCancel
                            confirmBtnText="Return to Songs Page"
                            cancelBtnText="Continue"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="primary"
                            onConfirm={() => {
                                history.push("/songs")
                            }}
                            onCancel={() => {
                                setAlertStatus(false)
                            }}
                        >
                            {isEdit ? ' Song updated!' : 'Song created!'}
                        </SweetAlert>
                    ) : null}
                </Container>
            </Styles>
        </Container>
    )
}