import React, { useState } from "react"
import { getAllUsersTestReq } from "../../../services/axios/axios.user"
import moment from "moment"
import { usePagination, useSortBy, useTable } from "react-table"
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap"
import styled from "styled-components"
import { changeRoleReq } from "../../../services/axios/axios.roles"

export const columnsContent = [

  {
    Header: "ID",
    accessor: "_id"
  },
  {
    Header: "Name",
    accessor: "email"
  },
  {
    Header: "Role",
    accessor: "role"
  },
  {
    Header: "Date Created",
    accessor: "date"
  },
  {
    Header: "Last Login",
    accessor: "loginDate"
  },
  {
    Header: "Phone number",
    accessor: "phoneNumber"
  },
  {
    Header: "Status",
    accessor: "activated"
  },
  {
    Header: "Action",
    accessor: "action"
  }


]

export const getTestUsers = async (skip, limit,
                                   sortState, realNameFilter,
                                   realPhoneFilter, realRoleFilter,
                                   activateFilterStatus, goUserEditPage,
                                   deactivateUser, setUserIdForDelete,
                                   setModalDeleteStatus, rolesArr, magicRender,
                                   setMagicRender) => {
  if (sortState?.id && sortState?.id === "initial") {
    const usersForPush = []
    const amount = 0
    return { usersForPush, amount }
  }
  let results = await getAllUsersTestReq(
    skip, limit,
    sortState, realNameFilter,
    realPhoneFilter, realRoleFilter,
    activateFilterStatus)
  if (results?.data?.usersArr?.length === 0 && skip !== 0) {

    results = await getAllUsersTestReq(
      0, limit,
      sortState, realNameFilter,
      realPhoneFilter, realRoleFilter,
      activateFilterStatus)
  }
  const usersFromBack = results?.data.usersArr

  const amount = results?.data?.amountG
  const usersForPush = []
  if (usersFromBack && usersFromBack?.length) {
    usersFromBack.map(user => {
      usersForPush.push({
        _id: (
          <div
            className={user.is_active ? "" : "inactive"}
            key={"id-" + user.id}
          >
            {user.id}
          </div>
        ),
        email: (
          <div
            className={user.is_active ? "" : "inactive"}
            key={"name-" + user.id}
          >
            {user?.name}
          </div>
        ),
        role: (
          <RoleCell
            key={"rolecell" + user._id}
            user={user}
            rolesArr={rolesArr}
            magicRender={magicRender}
            setMagicRender={setMagicRender}
          />
        ),
        date: (
          <div
            className={user.is_active ? "" : "inactive"}
            key={"date-" + user.id}
          >
            {moment(user?.created).format("MM/DD/YYYY")}
          </div>
        ),
        loginDate: (
          <div
            className={user.is_active ? "" : "inactive"}
            key={"date2-" + user.id}
          >
            {user?.login
              ? moment(user?.login).format("MM/DD/YYYY")
              : "-"}
          </div>
        ),
        phoneNumber: (
          <div
            searchvalue={user?.phone}
            className={user.is_active ? "" : "inactive"}
            key={"phone-" + user.id}
          >
            {user?.phone?.length === 36 ? "-" : user?.phone}
          </div>
        ),
        activated: (
          <div
            searchvalue={user.is_active.toString()}
            className={user.is_active ? "" : "inactive"}
            key={"phone-" + user.id}
          >
            {user.is_active ? "active" : "deactivated"}
          </div>

        ),
        action: (user?.role?.name !== "admin"&&
          <div className="action-in-table" key={"action-" + user.id}>
            <i
              key={"ico1-" + user.id}
              data-id={user.id}
              className="typcn typcn-edit perm-action"
              onClick={goUserEditPage}
            ></i>
            {user.is_active ? (
              <i
                key={"ico2-" + user.id}
                data-id={user.id}
                onClick={e => {
                  deactivateUser(user.id)
                }}
                className="typcn typcn-flash-outline perm-action"
              ></i>
            ) : (
              <i
                key={"ico3-" + user.id}
                className="typcn typcn-flash perm-action"
                data-id={user.id}
                onClick={e => {
                  deactivateUser(user.id)
                }}
              ></i>
            )}
            <i
              key={"ico4-" + user.id}
              className="typcn typcn-delete-outline perm-action"
              data-id={user.id}
              onClick={(e) => {
                setUserIdForDelete(e.target.dataset.id)
                setModalDeleteStatus(true)
              }}

            ></i>
          </div>
        )
      })
    })
  }
  return { usersForPush, amount }
}
export const Styles = styled.div`
  padding: 1rem;

  table {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #222434;
    border-spacing: 0;
    width: 100%;
    background-color: white;
    thead {
      border-top: none;
      tr {
        background-color: white;
        color: #5E6291;
        border-top: none;
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #E9EAF9;
      text-align: left;
      button {
        padding-left: 0;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    nav {
      padding: 0.5rem;
      padding-left: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: max-content;
      ul {
        margin: 0;
        padding: 0;
      }
    }
  }
`
const RoleCell = ({ user, rolesArr, magicRender, setMagicRender }) => {
  const [popoverright, setpopoverright] = useState(false)
  //role change handler
  const changeRole = async e => {
    setpopoverright(false)
    const userID = e.target.dataset.id
    const roleID = e.target.dataset.name
    const response = await changeRoleReq(roleID, userID)
    if (response?.data&&response.data?.message&&response.data.message==='success') {
      setMagicRender(!magicRender)
    } else {
      alert("something went wrong")
    }
  }

  return (
    <div className={user.is_active ? "ps-0" : "ps-0 inactive"} key={"role-" + user.id}>
      <Dropdown
        direction="left"
        isOpen={popoverright}
        toggle={() => setpopoverright(!popoverright)}
        className="ps-0"
      >
        <DropdownToggle className="btn btn-secondary" caret>
          <div
            className={user.is_active ? "ps-0" : "inactive ps-0"}
            key={"name-" + user.id}
            style={{ textAlign: "left" }}
          >
            {user?.role?.name}
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {user.role?.name!=='admin'&&rolesArr?.map((role, index) => {
            const string = role?.name
            if (role.is_active) {
              return (
                <DropdownItem
                  data-name={role._id}
                  data-id={user?.id}
                  key={"role2" + index + role._id}
                  className="drop-option mb-1"
                  onClick={changeRole}
                  style={{ textAlign: "center" }}
                >
                  {string.charAt(0).toUpperCase() + string.slice(1)}
                </DropdownItem>
              )
            }
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}



export const makeInvalid = (setFormValid, setMatchStatus, setIsPasswordValid, setIsStringAlphaNumValid,
                            setIsAnyNumberValid, setIsLowerLetterValid, setIsUpperLetterValid,
                            setIsLengthValid) => {
  setFormValid(false)
  setMatchStatus(false)
  setIsPasswordValid(false)
  setIsStringAlphaNumValid(false)
  setIsAnyNumberValid(false)
  setIsLowerLetterValid(false)
  setIsUpperLetterValid(false)
  setIsLengthValid(false)
}