import {
  TabContent,
  TabPane,
  Row,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardFooter,
  CardBody,
  Card
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"

import "./role-create.page.scss"
import { getDynPermsReq } from "../../../../services/axios/axios.perm_groups"
import { useHistory } from "react-router-dom"
import { createRoleReq } from "../../../../services/axios/axios.roles"
import SmallSwitch from "../../../../components/shared/smallSwitch"
import { setNavValues } from "../../../../store/nav_breadcrumbs/actions"
import { Styles } from "../../user/user_table"
import config from "../../../../config"

const RoleCreationPage = () => {
  //setState block=======================================================================
  //role constructor input states
  const [roleName, setRoleName] = useState("")
  const [roleDescription, setRoleDescription] = useState("")

  //data from backend
  const [rolePermsStatic, setRolePermsStatic] = useState([])
  const [rolePermsToRender, setRolePermsToRender] = useState([])
  ///other
  const [activeTab, setActiveTab] = useState("1")
  const [alertStatus, setAlertStatus] = useState(false)

  const history = useHistory()
  //on change handlers=========================================================================
  const nameOnChange = e => {
    const nameToChange = e.target.value
    setRoleName(nameToChange)
  }

  const descriptionOnChange = e => {
    const descriptionToChange = e.target.value
    setRoleDescription(descriptionToChange)
  }

  const searchFilterCreate = e => {
    const searchString = e.target.value
    if (searchString === "") {
      setRolePermsToRender(rolePermsStatic)
      return
    }

    const filteredArr = rolePermsStatic.filter(perm =>
      perm?.name.toLowerCase().includes(searchString.toLowerCase())
    )
    setRolePermsToRender(filteredArr)
  }

  const permsOnChange = (value, id, isChecked) => {
    const filteredArr = rolePermsToRender

    const elementOnCheck = filteredArr.find(perm => perm._id === id)
    elementOnCheck.isChecked = !isChecked
    setRolePermsToRender([...filteredArr])
    // setMagicRender(!magicRender)
  }
  ///async handlers

  //on save modal handler
  const saveChanges = async () => {
    const permArrReqObj = []
    rolePermsToRender.map(perm => {
      if (perm.isChecked) {
        permArrReqObj.push(perm._id)
      }
    })
    const reqObj = {
      name: roleName,
      description: roleDescription,
      permissions: permArrReqObj
    }
    console.log("reqObj :>> ", reqObj)
    if (!roleName) {
      setActiveTab("1")
      return
    }
    const response = await createRoleReq(reqObj)
    if (response?.data?.is_active) {
      setActiveTab("1")
      setAlertStatus(true)
    }
  }
  //get dyn perms
  const getDynPerms = async () => {
    const rolePermsToFill = []
    const response = await getDynPermsReq()
    if (response?.data && Array.isArray(response?.data)) {
      response.data.forEach(perm => {
        if (perm?.type === "role") {
          perm.isChecked = false
          rolePermsToFill.push(perm)
        }
      })
    }
    setRolePermsToRender(rolePermsToFill)
    setRolePermsStatic(rolePermsToFill)
  }

  const unassignedPerm = rolePermsToRender?.map((perm, index) => {
    if (!perm.isChecked && perm.is_active) {
      return (
        <Row className="perm-check" key={"right-switch" + perm._id}>
          <SmallSwitch
            perm={perm}
            permsOnChange={permsOnChange}
          />
        </Row>
      )
    }
  })

  //useEffect block

  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title1: "Roles", title2: "Create" }))
    getDynPerms()
  }, [])

  //inner components
  //small switch component

  return (
    <Container className="p-0 user-main-wrapper" fluid>
      <Styles className=" styles-inner-pages-wrapper">
        <Container fluid className="edit-user-wrapper hide-scrollbar">
          <AvForm>
            <div className="modal-body">
              <Col className="user-data-inputs">
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      <span className="d-none d-sm-block">General</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
              <span className="d-none d-sm-block">
                Permissions Assignment
              </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody>

                            <div className="mb-3">
                              <AvField
                                name="name"
                                label="Name  "
                                // value={userDataToRender.username}
                                onChange={nameOnChange}
                                placeholder="Add name"
                                type="text"
                                errorMessage="Add name"
                                validate={{
                                  required: { value: true }
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="fullname"
                                onChange={descriptionOnChange}
                                // value={userDataToRender.fullname}
                                label="Description  "
                                placeholder="Add description (not required)"
                                type="text"
                              />
                            </div>
                          </CardBody>
                          <CardFooter>
                            <button
                              type="button"
                              onClick={() => {
                                history.push("../roles/")
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={saveChanges}
                            >
                              Create Role
                            </button>
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody className="assign-content">

                            <Row className="perms-check">
                              <Col sm={12}>
                                <div className="mb-3">
                                  <AvForm>
                                    <AvField
                                      name="searchFilterCreate"
                                      onChange={searchFilterCreate}
                                      // value={userDataToRender.fullname}
                                      label="Search by name  "
                                      type="text"
                                    />
                                  </AvForm>
                                </div>
                              </Col>
                              {!rolePermsToRender?.length && (
                                <Col sm={12}>No results</Col>
                              )}
                              {rolePermsToRender &&
                              !!rolePermsToRender?.length && (
                                <Col sm={6} className="unassigned-col">
                                  <h5>Assigned permissions</h5>
                                  <br />
                                  {rolePermsToRender?.map((perm, index) => {
                                    if (perm.isChecked) {
                                      return (
                                        <Row className="perm-check" key={"left-switch" + perm._id}>
                                          <SmallSwitch
                                            perm={perm}
                                            permsOnChange={permsOnChange}
                                          />
                                        </Row>
                                      )
                                    }
                                  })}
                                </Col>
                              )}
                              {rolePermsToRender &&
                              !!rolePermsToRender?.length && (
                                <Col sm={6} className="assigned-col">
                                  <h5>Unassigned permissions</h5>
                                  <br />
                                  {unassignedPerm[0] === undefined &&unassignedPerm.length<2? <h5>No available</h5> : unassignedPerm}

                                </Col>
                              )}
                            </Row>


                          </CardBody>


                          <CardFooter>
                            <button
                              type="button"
                              onClick={() => {
                                history.push("../roles/")
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={saveChanges}
                            >
                              Create Role
                            </button>
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </div>
          </AvForm>
          {alertStatus ? (
            <SweetAlert
              title="Success!"
              success
              showCancel
              confirmBtnText="Return to Roles Page"
              cancelBtnText="Continue"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="primary"
              onConfirm={() => {
                history.push("../roles/")
              }}
              onCancel={() => {
                setAlertStatus(false)
              }}
            >
              Role created!
            </SweetAlert>
          ) : null}

        </Container>
      </Styles>
    </Container>
  )
}

export default RoleCreationPage
