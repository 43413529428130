import { Col } from "reactstrap"
import success_ico_forTable from "../../../../assets/default/images/ico/success_ico_forTable.png"
import success_ico_grey from "../../../../assets/default/images/ico/success_ico_grey.png"
import React from "react"

export default function PasswordCustomValidation({activatePasswordFields, isLengthValid,
                                                   isUpperLetterValid,isLowerLetterValid,
                                                   isAnyNumberValid,isStringAlphaNumValid,
                                                   matchStatus,}){
  return (
    <div>
      {activatePasswordFields && <Col
        sm={12}
        className={
          isLengthValid
            ? "create-pass-dyn-valid-para"
            : "create-pass-dyn-valid-para invalid"
        }
      >
        {isLengthValid && <img src={success_ico_forTable} alt="" />}
        {!isLengthValid && <img src={success_ico_grey} alt="" />}
        <div>at least 8 characters long</div>
      </Col>}
      {activatePasswordFields && <Col
        sm={12}
        className={
          isUpperLetterValid
            ? "create-pass-dyn-valid-para"
            : "create-pass-dyn-valid-para invalid"
        }
      >
        {isUpperLetterValid && <img src={success_ico_forTable} alt="" />}
        {!isUpperLetterValid && <img src={success_ico_grey} alt="" />}
        <div>contain an uppercase character</div>
      </Col>}
      {activatePasswordFields && <Col
        sm={12}
        className={
          isLowerLetterValid
            ? "create-pass-dyn-valid-para"
            : "create-pass-dyn-valid-para invalid"
        }
      >
        {isLowerLetterValid && <img src={success_ico_forTable} alt="" />}
        {!isLowerLetterValid && <img src={success_ico_grey} alt="" />}
        <div>contain a lowercase character</div>
      </Col>}
      {activatePasswordFields && <Col
        sm={12}
        className={
          isAnyNumberValid
            ? "create-pass-dyn-valid-para"
            : "create-pass-dyn-valid-para invalid"
        }
      >
        {isAnyNumberValid && <img src={success_ico_forTable} alt="" />}
        {!isAnyNumberValid && <img src={success_ico_grey} alt="" />}
        <div>contain a number</div>
      </Col>}
      {activatePasswordFields && <Col
        sm={12}
        className={
          isStringAlphaNumValid
            ? "create-pass-dyn-valid-para"
            : "create-pass-dyn-valid-para invalid"
        }
      >
        {isStringAlphaNumValid && <img src={success_ico_forTable} alt="" />}
        {!isStringAlphaNumValid && <img src={success_ico_grey} alt="" />}
        <div>contain a symbol</div>
      </Col>}
      {activatePasswordFields && <Col
        sm={12}
        className={
          matchStatus
            ? "create-pass-dyn-valid-para"
            : "create-pass-dyn-valid-para invalid"
        }
      >
        {matchStatus && <img src={success_ico_forTable} alt="" />}
        {!matchStatus && <img src={success_ico_grey} alt="" />}
        <div>passwords match</div>
      </Col>}
    </div>
  )
}