import axios from "axios"
import holdTokens from "../../services/hold_tokens/holdTokenPair"
import config from "../../config/index"
import { historyRef } from '../../components/VerticalLayout/Header';

axios.interceptors.request.use(request => {
  request.headers["Authorization"] = `Bearer ${
    holdTokens.getTokens()?.accessToken
  }`
  return request
})

axios.interceptors.response.use(
  response => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (!originalRequest.url.includes("/api/auth/refresh") && error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const response = await holdTokens.refreshNow()
      if (response === "REDIRECT") {
        return axios(originalRequest)
      }
      const { refreshToken, accessToken } = holdTokens.getTokens()
      holdTokens.setTokens(accessToken, refreshToken)
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken
      return axios(originalRequest)
    } else if(originalRequest.url.includes("/api/auth/refresh") && error?.response?.status === 401){
      if(historyRef && historyRef.current) {
        localStorage.clear();
        historyRef.current.push("/login")
      }
    }
    return Promise.reject(error)
  }
)

export async function signInReq(reqObj) {
  try {

    const response = await axios.post(config.axiosConnectionUrl + "auth/sign_in", {
      email: reqObj.email,
      password: reqObj.password,
      notes: reqObj.notes
    })

    if (response?.data?.email) {
      localStorage.setItem("email", response.data.email)
    }
    if (response.status === 200) {
      // console.log(response)
      return response
    }
  } catch (error) {

    if (
      error.message === "Request failed with status code 401" ||
      error.message === "Request failed with status code 400"
    ) {
      return "Unauthorized"
    }
    return error
  }
}

export async function signOutReq() {
  try {
    await axios.post(config.axiosConnectionUrl + "auth/sign_out")
  } catch (error) {
    return error
  }
}

export async function refreshTokensReq(refreshToken) {
  try {
    if (!refreshToken) {
      return "No refresh Token"
    }
    const response = await axios.post(config.axiosConnectionUrl + "auth/refresh", {
      refreshToken
    })
    return response
  } catch (error) {
    console.log("error.message refresh :>> ", error.response)

    return " "
  }
}