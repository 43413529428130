import React from "react"
import { Link } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import config from "../../config"
import { AvForm, AvField } from "availity-reactstrap-validation"
import MetaTags from "react-meta-tags"
import { resetPasswordReq } from "../../services/register_flow/reset_password"
import { useHistory, useParams } from "react-router-dom"
import { apiError } from "../../store/actions"

const resetpasswordHandler = async (resetPasswordToken, b, history) => {
  const confirmPassword = b.confirmPassword
  const password = b.password
  if (password != confirmPassword) {
    config.reduxStore.dispatch(apiError("Password Doesn't match"))
    return
  }
  const result = await resetPasswordReq(b.password, resetPasswordToken)
  if (result) {
    console.log(result);
    history.push("/ResetPasswordSuccess")
  } else {
    config.reduxStore.dispatch(apiError("You are not Authorized"))
  }
}
export default function ResetPassword(props) {
  const params = useParams()
  const history = useHistory()
  return (
    <div>
      <MetaTags>
        <title>{config.ResetPasswordPageProps.title}</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      {config.ResetPasswordPageProps.messageH}
                    </h5>
                    <p className="text-white-50">
                      {config.ResetPasswordPageProps.messageF}
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img
                        src={config.ResetPasswordPageProps.logo}
                        height="24"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={async (e, v) => {
                        console.log(e, v)
                        resetpasswordHandler(params["id"], v, history)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="confirmPassword"
                          label="confirmPassword"
                          type="password"
                          required
                          placeholder="Enter Confirm Password"
                        />
                      </div>

                      <Row className="mb-3">
                        <Col sm={6}>
                          <div className="form-check"></div>
                        </Col>
                        <Col sm={6} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Set
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          {/*<Link to="/forgot-password">*/}
                          {/*  <i className="mdi mdi-lock"></i> Forgot your*/}
                          {/*  password?*/}
                          {/*</Link>*/}
                        </div>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
