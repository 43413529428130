import { Modal } from "reactstrap"
import { AvField, AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

export default function CreateDefaultPermModal({ modalShow, setModalShow, editFormInputs, saveChangesCreate }) {
  return (
    <Modal
      isOpen={modalShow}
      toggle={() => {
        setModalShow(!modalShow)
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Default Permission Constructor (Beta)
        </h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <AvForm>
        <div className="modal-body">
          <div className="mb-3">
            <AvField
              name="name"
              label="Name  "
              onChange={editFormInputs}
              placeholder="Type name"
              type="text"
              errorMessage="Enter name"
              validate={{ required: { value: true } }}
            />
          </div>
          <div className="mb-3">
            <AvField
              name="desc"
              onChange={editFormInputs}
              label="Description  "
              placeholder="Type description"
              type="text"
              validate={{
                required: { value: true }
              }}
            />
          </div>

          <div className="mb-3">
            <AvField
              name="type"
              onChange={editFormInputs}
              label="Pick a type  "
              //   placeholder="Choose perm"
              type="select"
              errorMessage="Choose Type"
              validate={{
                required: { value: true }
              }}
            >
              <option></option>
              <option value="role">Role</option>
              {" "}
              <option value="subscription">Subscription</option>
              {" "}
            </AvField>
          </div>
          <div className="mb-3">
            <AvRadioGroup
              inline
              name="usage-type"
              label="Usage type"
              required
              className="radio"
              onChange={editFormInputs}
            >
              <AvRadio label="Internal" value="true" />
              <AvRadio label="External" value="false" />
            </AvRadioGroup>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModalShow(false)
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
            onClick={saveChangesCreate}
          >
            Create Permission
          </button>
        </div>
      </AvForm>
    </Modal>

  )
}