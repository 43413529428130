import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import ResetPassword from "../pages/Authentication/ResetPassword"
import ResetPasswordSuccess  from '../pages/Authentication/ResetPasswordSuccess';

import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import UserEditPage from "../pages/default/user-edit/user-edit.page.jsx"
import RolePage from "../pages/default/role/role.page.jsx"
import RoleCreationPage from "../pages/default/role/role-create/role-create.page"
import RoleEditPage from "../pages/default/role/role-edit/role-edit.page"
import DefaultPermissionsPage from "../pages/default/default-permissions/default-permissions.page"
import PermissionGroupsPage from "../pages/default/permission-groups/permission-group.page"
import GroupPermCreatePage from "../pages/default/permission-groups/perm_group-create/perm_group-create.page"
import GroupPermEditPage from "../pages/default/permission-groups/perm_group_edit/perm_group-edit.page"
import UsersPage from "../pages/default/user/user.page.jsx"
import SubscriptionPage from "../pages/default/subscriptions/subscription.page"
import CreateSubscriptionPage from "../pages/default/subscriptions/createSub.page"
import EditSubscriptionPage from "../pages/default/subscriptions/editSub.page"
import loginPage from "../pages/Authentication/Login"
import SongsPage from "../pages/Songs";
import SongCreate from "../pages/Songs/song-create"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  
  { path: "/users", component: UsersPage },
  { path: "/users/edit/:id", component: UserEditPage },
  { path: "/roles", component: RolePage },
  { path: "/roles/create", component: RoleCreationPage },
  { path: "/roles/:id", component: RoleEditPage },
  { path: "/default-permissions", component: DefaultPermissionsPage },
  { path: "/permission-groups", component: PermissionGroupsPage },
  { path: "/permission-groups/create", component: GroupPermCreatePage },
  { path: "/permission-groups/:id", component: GroupPermEditPage },
  { path: "/subscriptions", component: SubscriptionPage },
  { path: "/subscriptions/create", component: CreateSubscriptionPage },
  { path: "/subscriptions/:id", component: EditSubscriptionPage },



  { path: '/songs', component: SongsPage },
  { path: '/songs/create', component: SongCreate },
  { path: '/songs/:id', component: SongCreate },




  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/reset_password/:id", component: ResetPassword},
  { path: "/ResetPasswordSuccess", component: ResetPasswordSuccess},

  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
