import "./subscription.page.scss"

import { Alert, Button, Container } from "reactstrap"
import React, { useEffect, useRef, useState } from "react"
import Switch from "react-switch"
import { Offsymbol, OnSymbol } from "../../../components/shared/switchSymbolsDefault"
import { Styles } from "../user/user_table"
import { columnsContent, getSubs } from "./sub.table"
import { useSelector } from "react-redux"

import { setSortStateAc } from "../../../store/table/sort/actions"
import useThrottle from "../../../services/shared_handlers/debouncer"
import { useHistory } from "react-router-dom"
import { deleteSubReq, reactivateSubReq } from "../../../services/axios/axios.subs"
import SweetAlert from "react-bootstrap-sweetalert"
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import { Table } from "../../../components/shared/reactTable"
import config from "../../../config"


export default function SubscriptionPage() {

  //state block
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
  const [realNameFilter, setRealNameFilter] = React.useState("")
  const [activateFilterStatus, setActivateFilterStatus] = React.useState(false)
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertStatusWarn, setAlertStatusWarn] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [magicRender, setMagicRender]=useState(false)
  const [subidForDelete,setsubIdForDelete] = useState(false)
  const [modalDeleteStatus,setModalDeleteStatus]=useState(false);
  const [totalRecord, setTotalRecord] = React.useState(0)

  //consts
  const history = useHistory()
  const [debounce] = useThrottle(1000)
  const inputTitle = useRef(null)
  const columns = React.useMemo(
    () => columnsContent,
    []
  )
  //select sort value
  const sortVal = useSelector(state => state.sort.sortBy)

  //dispatch sorting
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]))
  }, [])

  //filters
  const nameFilter = (e) => {
    debounce(() => () => setRealNameFilter(e.target.value))
  }

  const resetFilters = () => {
    inputTitle.current.value = ""
    setRealNameFilter("")
    setActivateFilterStatus(false)
  }

  //async block

  //delete
  const deleteSub = async ()=>{
  const response = await deleteSubReq(subidForDelete)
    if(response.data==='deleted'){
      setAlertText("Plan deleted")
    } else{
      setAlertText("Something went wrong. Please contact administrator")
    }
    setModalDeleteStatus(false)
    setMagicRender(!magicRender)
    setAlertStatusWarn(true)
    setAlertText("Plan deleted")
    setTimeout(() => {
      setAlertStatusWarn(false)
    }, 2000)
  }

  //reactivate
  const reactivatesubider = async (id, status) => {
    const response = await reactivateSubReq(id, status)
    if (response.status === 200 && !response.data.status) {
      setAlertStatus(true)
      setAlertText("Plan activated")
      setTimeout(() => {
        setAlertStatus(false)
      }, 2000)
    } else if (response.status === 200 && response.data.status) {
      setAlertStatusWarn(true)
      setAlertText("Plan deactivated")
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 2000)
    }
    setMagicRender(!magicRender)
  }

  //fill table
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true)
    const startRow = pageSize * pageIndex
    const {
      subsToPush,
      amount
    } = await getSubs(
      startRow, pageSize,
      sortVal, realNameFilter,
      activateFilterStatus, reactivatesubider,
      setsubIdForDelete,setModalDeleteStatus,
      history
    )
    const serverData = subsToPush
    setData(serverData)
    setPageCount(Math.ceil(amount / pageSize))
    setTotalRecord(amount)
    setLoading(false)
  }, [sortVal, realNameFilter, activateFilterStatus,magicRender
  ])

  useEffect(()=>{
    config.reduxStore.dispatch(setNavValues({mainTitle:config.breadCrumbsFirstTitle,title2:'Subscriptions'}))
  },[])

  return (
    <Container fluid className="providers-main-wrapper">
      {modalDeleteStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="No"
          cancelBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            setModalDeleteStatus(false)
          }}
          onCancel={() => {
            deleteSub()
          }}
        >
          Are you sure want to delete this plan?
        </SweetAlert>
      ) : null}
      <Styles className="provider-white-card">
        <div className="card-title-desc user-table-filter-block mb-3">
          <div>
            <div className="col-md-12">
              <input
                ref={inputTitle}
                className="form-control sub-inputs"
                placeholder="Search by title"
                type="text"
                onChange={nameFilter}
              />
            </div>
          </div>
          <div className="clear-button me-5">
            <Button
              color="secondary"
              className="waves-effect waves-light"
              onClick={resetFilters}
            >
              Clear Filters
            </Button>
          </div>
          <div className="active-add-block">
            <div className="switch-component pb-1">
              <div className="mb-1">Show Deactivated</div>
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                value={activateFilterStatus}
                onClick={e => {
                }}
                onChange={e => {
                  setActivateFilterStatus(!activateFilterStatus)
                }}
                checked={activateFilterStatus}
              />
            </div>
            <i
              onClick={() => {
                history.push("../subscriptions/create")
              }}
              className="mdi mdi-bookmark-plus add-icon sub-add-btn"
            ></i>
          </div>
        </div>
        <Table
          columns={columns}
          data={data}
          pageCount={pageCount}
          totalRecord={totalRecord}
          fetchData={fetchData}
          loading={loading}
          onSort={onSortedChange}
        />
      </Styles>
      {alertStatus && (
        <div className='alert-wrapper'>
          <Alert color="success" className="bg-success text-white success-custom alert-card">
            <strong>Well done!</strong> {alertText}
          </Alert>
        </div>
      )}
      {alertStatusWarn && (
        <div className='alert-wrapper'>
          <Alert color="warning" className="bg-warning text-white success-custom alert-card">
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>
  )
}