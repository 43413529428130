import {
  Alert,
  Button,
  Container,
} from "reactstrap"


import Switch from "react-switch"
import React, { useState } from "react"
import { useEffect } from "react"
import {
  deactivateRolReq,
  deleteRoleReq,
} from "../../../services/axios/axios.roles"
import SweetAlert from "react-bootstrap-sweetalert"
import "./role.page.scss"
import { useHistory } from "react-router-dom"
import { Offsymbol, OnSymbol } from "../../../components/shared/switchSymbolsDefault"
import config from '../../../config/index'
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import { columnsContentRoles, getRoles } from "./role.table"

import { useSelector } from "react-redux"
import { setSortStateAc } from "../../../store/table/sort/actions"
import { Styles } from "../user/user_table"
import useThrottle from "../../../services/shared_handlers/debouncer"
import { Table } from "../../../components/shared/reactTable"

export default function RolePage() {
  //useState block
  const [data, setData] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [activateFilterStatus, setActivateFilterStatus] = useState(false)
  const [magicRender, setMagicRender] = useState(false)
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertStatusWarn, setAlerWarnStatus] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [deactivateAlertStatus, setDeactivateAlertStatus] = useState(false)
  const [activateAlertStatus, setActivateAlertStatus] = useState(false)
  const [deleteAlertStatus, setDeleteAlertRoleStatus] = useState(false)
  const [roleIdForReactivate, setRoleIdForReactivate] = useState("")
  const [roleIdForDelete, setRoleIdForDelete] = useState("")
  const [realNameFilter, setRealNameFilter] = useState("")
  const [nameValue,setNameValue] = useState('')
  const [totalRecord, setTotalRecord] = React.useState(0)


  const history = useHistory()
  //select sort value
  const sortVal = useSelector(state => state.sort.sortBy)
  const [debounce] = useThrottle(1000)
  //dispatch sorting
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]))
  }, [])



  async function reactivateRole() {
    const response = await deactivateRolReq(roleIdForReactivate)
    if (response?.data.message === "success") {
      setActivateAlertStatus(false)
      setDeactivateAlertStatus(false)
      if (response?.data.result) {
        setAlertStatus(true)
        setAlertText("Role activated")
      } else {

        setAlerWarnStatus(true)
        setAlertText("Role deactivated")
      }
      setMagicRender(!magicRender)
    }else {
      setAlerWarnStatus(true)
      setAlertText('Unknown error. please contact administrator')
      setActivateAlertStatus(false)
      setDeactivateAlertStatus(false)
    }
    setMagicRender(!magicRender)
    setTimeout(() => {
      setAlerWarnStatus(false)
      setAlertStatus(false)
    }, 2000)
  }

  function deleteRoleHandler(id) {
    setRoleIdForDelete(id)
    setDeleteAlertRoleStatus(true)
  }

  async function deleteRoleHandlerStep2() {
    const response = await deleteRoleReq(roleIdForDelete)
    if (response?.data?._id) {
      setAlertText("Role " +response?.data?._id + " deleted")
      setMagicRender(!magicRender)
    } else{
      setAlertText("Unknown error. please contact administrator")
    }
    setDeleteAlertRoleStatus(false)
    setAlerWarnStatus(true)

    setTimeout(() => {
      setAlerWarnStatus(false)
    }, 2000)
    setMagicRender(!magicRender)
  }

  ////---------------------------------------------------------

  //filter handlers
  const nameFilter = e => {
    setNameValue(e.target.value)
    debounce(() => () => setRealNameFilter(e.target.value))
  }
  ///clear all filter
  const resetFilters = () => {
    setNameValue('')
    setRealNameFilter("")
    setActivateFilterStatus(false)
  }


  //fill table
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true)
    const startRow = pageSize * pageIndex
    const {
      rolesToPush,
      amount
    } = await getRoles(
      startRow, pageSize,
      sortVal, realNameFilter,
      activateFilterStatus,
      setActivateAlertStatus,setDeactivateAlertStatus,
      setRoleIdForReactivate,deleteRoleHandler,
      history
    )
    setData(rolesToPush)
    setPageCount(Math.ceil(amount / pageSize))
    setTotalRecord(amount)
    setLoading(false)
  }, [sortVal, realNameFilter, activateFilterStatus, magicRender])

  //useeffect block
  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title2: "Roles" }))
  }, [])


  return (
    <Container fluid className="providers-main-wrapper">

      {deactivateAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="No"
          cancelBtnText="Deactivate"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            setDeactivateAlertStatus(false)
          }}
          onCancel={() => {
            reactivateRole()
          }}
        >
          Are you sure want to deactivate this role?
        </SweetAlert>
      ) : null}
      {activateAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="Activate"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            reactivateRole()
          }}
          onCancel={() => {
            setActivateAlertStatus(false)
          }}
        >
          Are you sure want to activate this role?
        </SweetAlert>
      ) : null}
      {deleteAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="No"
          cancelBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            setDeleteAlertRoleStatus(false)
          }}
          onCancel={() => {
            deleteRoleHandlerStep2()
          }}
        >
          Are you sure want to delete this role?
          <br />
          Deletion cannot be reverted.
        </SweetAlert>
      ) : null}
      <Styles className="provider-white-card">
        <div className="card-title-desc user-table-filter-block mb-3">
          <div>
            <div className="col-md-12">
              <input
                value={nameValue}
                className="form-control sub-inputs"
                placeholder="Search by title"
                type="text"
                onChange={nameFilter}
              />
            </div>
          </div>
          <div className="clear-button me-5">
            <Button
              color="secondary"
              className="waves-effect waves-light"
              onClick={resetFilters}
            >
              Clear Filters
            </Button>
          </div>
          <div className="active-add-block">
            <div className="switch-component pb-1">
              <div className="mb-1">Show Deactivated</div>
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                value={activateFilterStatus}
                onClick={e => {
                }}
                onChange={e => {
                  setActivateFilterStatus(!activateFilterStatus)
                }}
                checked={activateFilterStatus}
              />
            </div>
            <i
              onClick={() => {
                history.push("../roles/create")
              }}
              className="mdi mdi-bookmark-plus add-icon sub-add-btn"
            ></i>
          </div>
        </div>
        <Table
          columns={columnsContentRoles}
          data={data}
          pageCount={pageCount}
          totalRecord={totalRecord}
          fetchData={fetchData}
          loading={loading}
          onSort={onSortedChange}
        />
      </Styles>
      {alertStatus && (
        <div className="alert-wrapper">
          <Alert color="success" className="bg-success text-white success-custom alert-card">
            <strong>Well done!</strong> {alertText}
          </Alert>
        </div>
      )}
      {alertStatusWarn && (
        <div className="alert-wrapper">
          <Alert color="warning" className="bg-warning text-white success-custom alert-card">
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>
  )
}

