import axios from "axios"

import config from "../../config/index"

///get all dynamic perms
export async function getDynPermsReq() {
  try {
    const response = await axios.get(config.axiosConnectionUrl+'dyn_perm/')
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

export async function getDynPermsForTableReq(skip, limit,
                                             sortVal, realNameFilter,
                                             activateFilterStatus) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+'dyn_perm/'
      + "table/query?skip="+skip
      +'&limit='+limit
      +'&sortid='+sortVal?.id
      +'&sortdesc='+sortVal?.desc
      +'&namefilter='+realNameFilter
      +'&activated='+activateFilterStatus)
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

export async function getDynPermReq(id) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+'dyn_perm/' + id)
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

///create dynamic perm
export async function createPermReq(reqObj) {
  try {
    const response = await axios.post(config.axiosConnectionUrl+'dyn_perm/', reqObj)
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}

//delete dyn perm
export async function deleteDynPermReq(id) {
  try {
    const response = await axios.delete(config.axiosConnectionUrl+'dyn_perm/' + id)
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}

export async function updateDynPerm(reqObj) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+'dyn_perm/', reqObj)
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}

export async function reactivateDynPerm(id) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+'dyn_perm/' + id)
    return response
  } catch (error) {
    console.log(error)
    return error.response
  }
}