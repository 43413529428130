import React from "react"
import MetaTags from 'react-meta-tags';
import config from "../../config"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import { Col, Container, Row } from "reactstrap"

const ResetPasswordSuccess = () => {
  return (
    <React.Fragment>
      <div>
      <MetaTags>
          <title>Reset Password Success </title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">

            <Col cl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    
                    <div className="my-auto">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                          <img
                          src={config.ResetPasswordPageProps.logo}
                          alt=""
                          height="40"
                          className="auth-logo-dark"
                        />
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Success</h4>
                          <p>
                           Password Updated SuccessFully
                            </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPasswordSuccess;
