import axios from "axios"

import config from "../../config/index"

export async function resetPasswordReq(password, resetPasswordToken) {
    try {
      const response = await axios.post(config.axiosConnectionUrl+ 'auth/reset/', {
        resetPasswordToken: resetPasswordToken,
        newPassword: password
      })
      return response
    } catch (error) {
      console.log(error)
      // return error.response.data.message;
    }
}