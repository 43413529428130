import { combineReducers } from "redux"


import  Layout from  '../defaultCore/src/store/layout/reducer'
import Login from  '../defaultCore/src/store/auth/login/reducer'
import sort from  '../defaultCore/src/store/table/sort/reducer'
import nav from '../defaultCore/src/store/nav_breadcrumbs/reducer'




const rootReducer = combineReducers({
  Layout,
  Login,
  sort,
  nav,
})

export default rootReducer
