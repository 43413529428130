
import { refreshTokensReq } from "../axios/axios.auth"

class HoldTokens {
  constructor() {}

  setTokens(accessToken, refreshToken) {
    if (accessToken && refreshToken) {
      localStorage.setItem("accessToken", accessToken)
      localStorage.setItem("refreshToken", refreshToken)
      return
    }

    ///for research - temp decision
    console.log("Something go wrong => tokens")
  }

  getTokens() {
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")
    if (accessToken && refreshToken) {
      return { accessToken, refreshToken }
    }

    return
  }

  refresh() {
    setInterval(async () => {
      const tokens = this.getTokens()
      const response = await refreshTokensReq(tokens?.refreshToken)
      if (typeof response !== "string") {
        const accessToken = response.data.resp.accessToken
        const refreshToken = response.data.resp.refreshToken
        this.setTokens(accessToken, refreshToken)
      }
    }, 5 * 60 * 1000)
  }

  async refreshNow() {
    try {
      const tokens = this.getTokens()
      const response = await refreshTokensReq(tokens?.refreshToken)
      if (typeof response !== "string") {
        const accessToken = response.data.resp.accessToken
        const refreshToken = response.data.resp.refreshToken
        this.setTokens(accessToken, refreshToken)
      }
      return response
    }catch (e) {
      if (e.message === "Cannot read properties of undefined (reading 'resp')"){
        localStorage.clear();
        window.location.reload();
        return 'REDIRECT'
      }
      return e
    }

  }
}

const holdTokens = new HoldTokens()

export default holdTokens
