import axios from "axios"

import config from "../../config/index"
import holdTokens from "../hold_tokens/holdTokenPair"


export async function createUser(reqObj) {
  try {
    const response = await axios.post(
      config.axiosConnectionUrl+ 'users/'+ "create",
      reqObj
    )
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function deactivateUserReq(id) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+ 'users/' + "reactivate/" + id)
    return response
  } catch (e) {
    console.log(e)
    return e
  }


}

//get all users
export async function getAllUsersTestReq(skip, limit, sortState, realNameFilter, realPhoneFilter, realRoleFilter, activateFilterStatus) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+ 'users/'
      + "table/query?skip="+skip
      +'&limit='+limit
      +'&sortid='+sortState?.id
      +'&sortdesc='+sortState?.desc
      +'&namefilter='+realNameFilter
      +'&phonefilter='+realPhoneFilter
      +'&rolefilter='+realRoleFilter
      +'&activated='+activateFilterStatus
    )

    return response
  } catch (error) {
    console.log(error.message)
  }
}


//get 1 user
export async function getUserReq(id) {
  const response = await axios.get(config.axiosConnectionUrl+ 'users/' + `${id}`)
  return response
}

//update user

export async function updateUserReq(id, data) {
  const response = await axios.put(config.axiosConnectionUrl+ 'users/' + `${id}`, data)
  return response
}

//attach team to user
export async function attachTeamReq(id, teamID, status) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+ 'users/' + "team/" + id + "/" + teamID, { status: status })
    return response
  } catch (e) {
    console.log(e)
  }
}

export async function deleteUserReq(id) {
  try {
    const response = await axios.delete(
      config.axiosConnectionUrl+ 'users/' + id
    )
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

export async function statsForUsersPageReq() {
  try {
    const response = await axios.get(
      config.axiosConnectionUrl+ 'users/' + "stats"
    )
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}




